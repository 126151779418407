import React, { FC, useState } from 'react';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import Header from 'components/common/Header';
import { style, variants } from './Login';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import request from 'api/request';
import { toast } from 'react-toastify';

const ResetPassword: FC = () => {
  const nav = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [pass, setPass] = useState({
    token,
    password: '',
    confirm_password: '',
  });

  const handleChange = (name: string, value: string): void => {
    setPass((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = (): void => {
    if (token) {
      const body = pass;
      request('put', '/auth/newPassword', body)
        .then((res) => {
          console.log(res);
          nav('/login');
        })
        .catch((err) => {
          console.log('🚀 ~ handleReset ~ err:', err);
          toast.error(<b>There was an error. Please try again later.</b>);
        });
    }
  };
  return (
    <>
      <Header logo back />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <AnimatePresence>
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={variants}
            className="login_container"
            style={{
              gap: '52px',
            }}
          >
            <Box display="flex" flexDirection="column" gap="5px" textAlign="center">
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '24px',
                    marginTop: '40px',
                  },
                }}
                fontWeight="bold"
                fontSize="32px"
                color="white"
              >
                Reset Password
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography fontWeight="regular" color="white">
                  Please enter a new your Password.
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap="20px" width="100%">
              <Box display="flex" flexDirection="column" gap="8px" width="100%">
                <Typography color="white">Password</Typography>
                <TextField
                  type="password"
                  autoComplete="new-password"
                  sx={style}
                  inputProps={{
                    style: {
                      padding: '16px 0px 16px 32px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <Eye />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '12px',
                      color: 'white',
                      paddingRight: '32px',
                    },
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="*******"
                  fullWidth
                  name="password"
                  value={pass.password}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap="8px" width="100%">
                <Typography color="white">Password</Typography>
                <TextField
                  type="password"
                  autoComplete="new-password"
                  sx={style}
                  inputProps={{
                    style: {
                      padding: '16px 0px 16px 32px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <Eye />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '12px',
                      color: 'white',
                      paddingRight: '32px',
                    },
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="*******"
                  fullWidth
                  name="confirm_password"
                  value={pass.confirm_password}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Button
              onClick={handleReset}
              fullWidth
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                borderRadius: '12px',
                backgroundColor: 'white',
                textTransform: 'unset',
                color: 'black',
                padding: '12px 24px',
              }}
            >
              Reset
            </Button>
          </motion.div>
        </AnimatePresence>
      </Box>
    </>
  );
};

export default ResetPassword;
