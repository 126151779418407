import React, { FC, useState } from 'react';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as Google } from 'assets/google.svg';
// import { ReactComponent as Apple } from 'assets/apple.svg';
import { ReactComponent as Facebook } from 'assets/facebook.svg';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import '../css.responsiveness/login.responsive.css';
import request from 'api/request';
import { IUserResponse } from 'types/user';
import { baseUrl } from 'config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import toast from 'react-hot-toast';

export const style = {
  input: {
    padding: '16px 32px',
    '@media (max-width: 500px)': {
      padding: '12px 32px',
    },
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

export const variants = {
  hidden: { opacity: 0.3 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const Login: FC = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [show, setShow] = useState(false);

  const handleChange = (name: string, value: string): void => {
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = (): void => {
    setLoading(true);
    const body = user;
    request<IUserResponse>('post', '/auth/login', body)
      .then((res) => {
        console.log(res);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        window.location.href = '/home';
        setLoading(false);
      })
      .catch((err) => {
        console.log('🚀 ~ handleLogin ~ err:', err);
        setLoading(false);
        toast.error(<b>{err.response.data.message}</b>);
      });
  };
  const handleSocialLogin = (platform: string): void => {
    if (platform === 'google') {
      window.location.href = `${baseUrl}/auth/google`;
    } else {
      window.location.href = `${baseUrl}/auth/facebook`;
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
      }}
    >
      <AnimatePresence>
        <motion.div
          initial={'hidden'}
          animate={'visible'}
          exit={'hidden'}
          variants={variants}
          className="login_container"
        >
          <Box
            sx={{
              '@media (max-width: 500px)': {
                width: '146px',
              },
            }}
          >
            <Logo width="100%" />
          </Box>
          <Box height="100%" display="flex" flexDirection="column" gap="20px" width="100%">
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Typography fontSize="16px" color="white">
                Email
              </Typography>
              <TextField
                sx={style}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    color: 'white',
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter your email address"
                fullWidth
                autoComplete="false"
                name="email"
                type="email"
                value={user?.email}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin();
                  }
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Typography color="white">Password</Typography>
              <TextField
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin();
                  }
                }}
                sx={style}
                autoComplete="new-password"
                type={show ? 'text' : 'password'}
                // inputProps={{
                //   style: {
                //     padding: '16px 0px 16px 32px',
                //   },
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {show ? (
                        <Box
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          <VisibilityIcon htmlColor="white" />
                        </Box>
                      ) : (
                        <Box
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          <VisibilityOffIcon htmlColor="white" />
                        </Box>
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '12px',
                    color: 'white',
                    paddingRight: '32px',
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                placeholder="*******"
                fullWidth
                name="password"
                value={user?.password}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
              />
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  onClick={() => {
                    nav('/forgetPassword');
                  }}
                  color="white"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Forgot Password ?
                </Typography>
              </Box>
            </Box>
            <Button
              disabled={loading}
              onClick={handleLogin}
              fullWidth
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                borderRadius: '12px',
                backgroundColor: 'white',
                textTransform: 'unset',
                color: 'black',
                padding: '12px 24px',
                '@media (max-width: 500px)': {
                  marginTop: '52px',
                },
              }}
            >
              Login
            </Button>
            <Box display="flex" gap="10px" alignItems="center" justifyContent="center">
              <Box borderBottom="1px solid white" width="30%" />
              <Typography fontSize="12px" color="white">
                OR
              </Typography>
              <Box borderBottom="1px solid white" width="30%" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="18px"
              sx={{
                '@media (max-width: 1440px)': {
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
              }}
            >
              <Box
                display="none"
                sx={{
                  '@media (max-width: 1440px)': {
                    display: 'block',
                  },
                }}
              >
                <Google
                  width={32}
                  height={32}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleSocialLogin('google');
                  }}
                />
              </Box>
              {/* <Box
                display="none"
                sx={{
                  '@media (max-width: 1440px)': {
                    display: 'block',
                  },
                }}
              >
                <Apple width={32} height={32} />
              </Box> */}
              <Box
                display="none"
                sx={{
                  '@media (max-width: 1440px)': {
                    display: 'block',
                  },
                }}
              >
                <Facebook
                  width={32}
                  height={32}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleSocialLogin('facebook');
                  }}
                />
              </Box>
              <Button
                onClick={() => {
                  handleSocialLogin('google');
                }}
                sx={{
                  borderRadius: '12px',
                  border: '1px solid white',
                  textTransform: 'unset',
                  color: 'white',
                  padding: '16px',
                  background: 'transparent',
                  '@media (max-width: 1440px)': {
                    display: 'none',
                  },
                }}
                startIcon={<Google />}
              >
                Continue With Google
              </Button>
              {/* <Button
                sx={{
                  borderRadius: '12px',
                  border: '1px solid white',
                  textTransform: 'unset',
                  color: 'white',
                  padding: '16px',
                  background: 'transparent',
                  '@media (max-width: 1440px)': {
                    display: 'none',
                  },
                }}
                startIcon={<Apple />}
              >
                Continue With Apple
              </Button> */}
              <Button
                onClick={() => {
                  handleSocialLogin('facebook');
                }}
                sx={{
                  borderRadius: '12px',
                  border: '1px solid white',
                  textTransform: 'unset',
                  color: 'white',
                  padding: '16px',
                  background: 'transparent',
                  '@media (max-width: 1440px)': {
                    display: 'none',
                  },
                }}
                startIcon={<Facebook />}
              >
                Continue With Facebook
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                '@media (max-width: 500px)': {
                  flexDirection: 'column',
                  marginTop: 'auto',
                },
              }}
            >
              <Typography margin="12px 0" color="white">
                You don’t have an account?{' '}
                <Typography
                  component="span"
                  onClick={() => {
                    nav('/register');
                  }}
                  sx={{
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      display: 'none',
                    },
                  }}
                >
                  Signup
                </Typography>
              </Typography>
              <Typography
                onClick={() => {
                  nav('/register');
                }}
                color="white"
                sx={{
                  display: 'none',
                  cursor: 'pointer',
                  '@media (max-width: 500px)': {
                    display: 'block',
                  },
                }}
              >
                Signup
              </Typography>
            </Box>
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default Login;
