import React, { FC, useEffect, useState } from 'react';
import { Box, Typography, Switch, styled } from '@mui/material';
import Header from 'components/common/Header';
import { ReactComponent as Edit } from 'assets/edit-2.svg';
import { ReactComponent as Subscribe } from 'assets/mobile.svg';
import { ReactComponent as Supportpic } from 'assets/support.svg';
import { SwitchProps } from '@mui/material/Switch';
import ImagePicker from 'components/common/imagePicker.component';
import Drawer from 'components/common/Drawer';
import { motion, AnimatePresence } from 'framer-motion';
import { variants } from 'unAuth/Login';
import EditProfile from 'components/popups/editProfile.popup';
import ChangePassword from 'components/popups/changePassword.popup';
import Support from 'components/popups/support.popup';
import Plan from 'components/popups/plan.popup';
import RequestSubmitted from 'components/popups/requestSubmitted';
import request from 'api/request';
import { toast } from 'react-toastify';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(1, 163, 36, 1)' : 'rgba(1, 163, 36, 1)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: 'rgba(1, 163, 36, 1)',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ProfileSetting: FC = () => {
  // const nav = useNavigate();
  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const [openSupport, setOpenSupport] = useState<boolean>(false);
  const [openPlan, setOpenPlan] = useState<boolean>(false);
  const userJson = localStorage.getItem('user');
  const parsedUser = userJson ? JSON.parse(userJson) : undefined;
  const [user, setUser] = useState(parsedUser);
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [updateUser, setUpdateUser] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });
  const [updateUserPassword, setUpdateUserPassword] = useState({
    old: '',
    new: '',
    reEnter: '',
  });

  const handleChange = (name: string, value: string): void => {
    setUpdateUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangePassword = (name: string, value: string): void => {
    setUpdateUserPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdatePassword = (): void => {
    const body = {
      oldPassword: updateUserPassword.old,
      newPassword: updateUserPassword.new,
      confirmPassword: updateUserPassword.reEnter,
    };
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('patch', `/users/reset/${user._id}`, body)
      .then((res) => {
        console.log(res);
        setMessage('Password Updated Successfully');
        setOpenToast(true);
        handlePasswordClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const handleClose = (): void => {
    setOpenProfile(false);
  };
  const handlePasswordClose = (): void => {
    setOpenPassword(false);
    setUpdateUserPassword({
      old: '',
      new: '',
      reEnter: '',
    });
  };

  const handleCloseSupport = (): void => {
    setOpenSupport(false);
  };
  const handleClosePlan = (): void => {
    setOpenPlan(false);
  };

  useEffect(() => {
    window.addEventListener('storage', () => {
      const userJson = localStorage.getItem('user');
      const user = userJson ? JSON.parse(userJson) : undefined;
      setUser(user);
    });
  });
  return openPlan ? (
    <Plan handleClose={handleClosePlan} openPlan={openPlan} />
  ) : (
    <>
      <Drawer />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={'column'}
        height="100vh"
        paddingBottom={'20px'}
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Header logo back menu />
        <AnimatePresence>
          <motion.div initial={'hidden'} animate={'visible'} exit={'hidden'} variants={variants}>
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                flexDirection: 'column',
                width: '20vw',
                '@media (max-width: 1600px)': {
                  width: '25vw',
                },
                '@media (max-width: 1200px)': {
                  width: '30vw',
                },
                '@media (max-width: 800px)': {
                  width: '36vw',
                },
                '@media (max-width: 500px)': {
                  width: '70vw',
                },
              }}
            >
              <ImagePicker
                height="150px"
                user={user}
                setOpenToast={setOpenToast}
                setMessage={setMessage}
              />
              <Box>
                <Typography
                  color={'white'}
                  variant="h5"
                  fontWeight={'bold'}
                  fontFamily={'inter'}
                  textTransform="capitalize"
                  mb={'12px'}
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography color={'white'} fontFamily={'inter'}>
                  {user?.email}
                </Typography>
              </Box>
              <Box
                width={'100%'}
                border={'1px solid white'}
                borderRadius={'12px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={'16px 26px'}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenProfile(true);
                }}
              >
                <Typography color={'white'} fontFamily={'inter'}>
                  Edit Profile
                </Typography>
                <Edit />
              </Box>
              <Box
                onClick={() => {
                  setOpenPlan(true);
                }}
                width={'100%'}
                border={'1px solid white'}
                borderRadius={'12px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={'16px 26px'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Typography color={'white'} fontFamily={'inter'}>
                  Change Subscription
                </Typography>
                <Subscribe />
              </Box>
              <Box
                width={'100%'}
                border={'1px solid white'}
                borderRadius={'12px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={'16px 22px 16px 26px'}
              >
                <Typography color={'white'} fontFamily={'inter'}>
                  Email Notification
                </Typography>
                <IOSSwitch sx={{ m: 0 }} defaultChecked />
              </Box>
              <Box
                onClick={() => {
                  setOpenSupport(true);
                }}
                width={'100%'}
                border={'1px solid white'}
                borderRadius={'12px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={'16px 26px'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Typography color={'white'} fontFamily={'inter'}>
                  Withdrawn Support
                </Typography>
                <Supportpic />
              </Box>
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
      <EditProfile
        handleClose={handleClose}
        open={openProfile}
        setOpenPassword={setOpenPassword}
        userId={user._id}
        handleChange={handleChange}
        updateUser={updateUser}
        setOpenToast={setOpenToast}
        setMessage={setMessage}
      />
      <ChangePassword
        handleClose={handlePasswordClose}
        open={openPassword}
        updateUserPassword={updateUserPassword}
        handleChange={handleChangePassword}
        userId={user._id}
        handleUpdatePassword={handleUpdatePassword}
      />
      {openSupport && <Support handleClose={handleCloseSupport} open={openSupport} />}
      <RequestSubmitted
        message={message}
        open={openToast}
        handleClose={() => {
          setOpenToast(false);
        }}
      />
    </>
  );
};

export default ProfileSetting;
