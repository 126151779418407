import { io, Socket } from 'socket.io-client';
import { mediaUrl } from './config';

const URL = mediaUrl;
const token = localStorage.getItem('token');
export const socket: Socket = io(URL, {
  autoConnect: false,
  reconnection: true,
  extraHeaders: {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    Authorization: `Bearer ${token}`,
  },
});
