import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import request from 'api/request';
import toast from 'react-hot-toast';

// import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
// import { styled } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '30%',
  bgcolor: 'black',
  borderRadius: '20px',
  border: '1px solid white',
  boxShadow: 54,
  p: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  '&:focus-visible': {
    outline: 'none',
  },
};

interface IAddNote {
  open: boolean;
  handleClose: () => void;
  deleteNoteId: string;
  getUserNotesByPropertyId: () => void;
  setOpenToast: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
}

const AddNote: FC<IAddNote> = ({
  open,
  handleClose,
  deleteNoteId,
  getUserNotesByPropertyId,
  setOpenToast,
  setMessage,
}) => {
  const handleDeleteNote = (): void => {
    request('delete', `/watchlist/notes/${deleteNoteId}`)
      .then(() => {
        getUserNotesByPropertyId();
        handleClose();
        setOpenToast(true);
        setMessage('Note Deleted Successfully!');
      })
      .catch((err) => {
        console.log('🚀 ~ handleDeleteNote ~ err:', err);
        toast.error(<b>There was an error. Please try again later.</b>);
      });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
          <Typography fontWeight="bold" fontSize="32px" color="white">
            Delete Your Note
          </Typography>
        </Box>
        <Typography fontWeight="bold" color="white" fontSize={'18px'}>
          Are you certain you want to delete this note?
        </Typography>

        <Box width="100%" display="flex" flexDirection="column" gap={'10px'}>
          <Button
            onClick={handleDeleteNote}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'black',
              },
              borderRadius: '12px',
              backgroundColor: 'black',
              textTransform: 'unset',
              color: 'white',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNote;
