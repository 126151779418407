import React, { FC, useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import emailjs from 'emailjs-com';

interface ISupport {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '30%',
  '@media (max-width: 500px)': {
    width: '80%',
    p: '20px 20px',
  },
  bgcolor: 'black',
  borderRadius: '20px',
  border: '1px solid white',
  boxShadow: 24,
  p: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const inputStyle = {
  input: {
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

const Support: FC<ISupport> = ({ open, handleClose }) => {
  const [suppot, setSupport] = useState({
    name: '',
    email: '',
    description: '',
  });

  const handleSendEmail = (): void => {
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', suppot, 'YOUR_USER_ID').then(
      (result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      },
      (error) => {
        console.log(error.text);
        alert('Failed to send message.');
      },
    );
  };

  const handleChange = (name: string, value: string): void => {
    setSupport((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
          <Typography
            fontWeight="bold"
            fontSize="32px"
            color="white"
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '22px',
              },
            }}
          >
            Support
          </Typography>
          <Box display="flex" justifyContent="center">
            <Typography
              width="60%"
              fontWeight="regular"
              color="white"
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '14px',
                },
              }}
            >
              Contact Support for any Information.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="15px" width="100%">
          <Box display="flex" gap="20px" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Typography fontSize="16px" color="white">
                Username
              </Typography>
              <TextField
                sx={inputStyle}
                inputProps={{
                  style: {
                    padding: '16px 32px',
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    color: 'white',
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                placeholder="First Name"
                fullWidth
                name="name"
                value={suppot.name}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography fontSize="16px" color="white">
              Email
            </Typography>
            <TextField
              sx={inputStyle}
              inputProps={{
                style: {
                  padding: '16px 32px',
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  color: 'white',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter your email address"
              fullWidth
              type="email"
              name="email"
              value={suppot.email}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography fontSize="16px" color="white">
              Description
            </Typography>
            <textarea
              autoFocus
              rows={5}
              style={{
                border: '1px solid white',
                backgroundColor: 'transparent',
                borderRadius: '12px',
                padding: '16px 32px',
                color: 'white',
                outline: 'none',
              }}
              name="description"
              value={suppot.description}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              placeholder="Feel Free to Write"
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap={'10px'}>
          <Button
            onClick={handleSendEmail}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Support;
