import { IWatchlistResponse } from 'types/user';
import API from 'api/request';

const loggedInUser = localStorage.getItem('user');
const user = loggedInUser ? JSON.parse(loggedInUser) : '';

export const getAllWatchList = (updateStore: any): void => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  API<IWatchlistResponse>('get', `/watchlist/${user?._id}`)
    .then((res) => {
      console.log(res);
      if (res.data.watchList) {
        updateStore?.updateStore({
          watchlistCounter: res.data.watchList.length,
          watchlist: res.data.watchList,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
