import { Box, Typography } from '@mui/material';
import Header from 'components/common/Header';
import React, { FC } from 'react';

const TermsAndConditions: FC = () => {
  return (
    <>
      <Header back logo />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        // height="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={'column'}
          mt={'140px'}
          padding={'10px 10%'}
          sx={{
            '@media (max-width: 500px)': {
              marginTop: '70px',
            },
          }}
        >
          <Typography
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '22px',
              },
            }}
            color={'white'}
            fontSize={'32px'}
            fontWeight={600}
            mb={'15px'}
          >
            Terms & Conditions
          </Typography>
          <Typography
            fontSize="22px"
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '18px',
              },
            }}
            color={'white'}
          >
            <strong>About the Application</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) Welcome to WTIHDRAWN. (Application). The Application provides users with the
              ability to search and track Real Estate data. (b) The Application is operated by
              WITHDRAWN APP - FZCO (License Number 47391). Access to and use of the Application, or
              any of its associated Products or Services, is provided by WITHDRAWN APP - FZCO.
              Please read these terms and conditions (Terms) carefully. By using, browsing and/or
              reading the Application, this signifies that you have read, understood and agree to be
              bound by the Terms. If you do not agree with the Terms, you must cease usage of the
              Application, or any of Services, immediately. (c) WITHDRAWN APP - FZCO reserves the
              right to review and change any of the Terms by updating this page at its sole
              discretion. When WITHDRAWN APP - FZCO updates the Terms, it will use reasonable
              endeavors to provide you with notice of updates to the Terms. Any changes to the Terms
              take immediate effect from the date of their publication. Before you continue, we
              recommend you keep a copy of the Terms for your records.
            </Typography>
            <strong>Acceptance of the Terms</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) You accept the Terms by remaining on the Application. You may also accept the
              Terms by clicking to accept or agree to the Terms where this option is made available
              to you by WITHDRAWN APP - FZCO in the user interface.
            </Typography>
            <strong>Subscription to use the Services</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) In order to access the Services, you must first purchase a subscription through
              the Application (Subscription) and pay the applicable fee for the selected
              Subscription (Subscription Fee). (b) In purchasing the Subscription, you acknowledge
              and agree that it is your responsibility to ensure that the Subscription you elect to
              purchase is suitable for your use. (c) Once you have purchased the Subscription, you
              will then be required to register for an account through the Application before you
              can access the Services (Account). (d) As part of the registration process, or as part
              of your continued use of the Services, you may be required to provide personal
              information about yourself (such as identification or contact details), including: (i)
              Full Name (ii) Password (iii) Email Address (iv) User&apos;s Residing Address (e) You
              warrant that any information you give to WITHDRAWN APP - FZCO in the course of
              completing the registration process will always be accurate, correct and up to date.
              (f) Once you have completed the registration process, you will be a registered member
              of the Application (Member) and agree to be bound by the Terms. As a Member you will
              be granted immediate access to the Services from the time you have completed the
              registration process until the subscription period expires (Subscription Period). (g)
              You may not use the Services and may not accept the Terms if: (i) you are not of legal
              age to form a binding contract with WITHDRAWN APP - FZCO; or (ii) you are a person
              barred from receiving the Services under any Local or International law including the
              country in which you are resident or from which you use the Services.
            </Typography>
            <strong>Your obligations as a Member</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) As a Member, you agree to comply with the following: (i) you will use the Services
              only for purposes that are permitted by: (A) the Terms; and (B) any applicable law,
              regulation or generally accepted practices or guidelines in the relevant
              jurisdictions; (ii) you have the sole responsibility for protecting the
              confidentiality of your password and/or email address. Use of your password by any
              other person may result in the immediate cancellation of the Services; (iii) any use
              of your registration information by any other person, or third parties, is strictly
              prohibited. You agree to immediately notify WITHDRAWN APP - FZCO of any unauthorized
              use of your password or email address or any breach of security of which you have
              become aware; (iv) access and use of the Application is limited, non-transferable and
              allows for the sole use of the Application by you for the purposes of WITHDRAWN APP -
              FZCO providing the Services; (v) you will not use the Services or the Application in
              connection with any commercial endeavors except those that are specifically endorsed
              or approved by the management of WITHDRAWN APP - FZCO; (vi) you will not use the
              Services or Application for any illegal and/or unauthorized use which includes
              collecting email addresses of Members by electronic or other means for the purpose of
              sending unsolicited email or unauthorized framing of or linking to the Application;
              (vii) you agree that commercial advertisements, affiliate links, and other forms of
              solicitation may be removed from the Application without notice and may result in
              termination of the Services. Appropriate legal action will be taken by WITHDRAWN APP -
              FZCO for any illegal or unauthorized use of the Application; and (viii) you
              acknowledge and agree that any automated use of the Application or its Services is
              prohibited.
            </Typography>
            <strong>Payment</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) All payments made in the course of your use of the Services are made using Stripe,
              Paypal, Apple Pay and may include such other payment gateways such as Afterpay. In
              using the Application, the Services or when making any payment in relation to your use
              of the Services, you warrant that you have read, understood and agree to be bound by
              the Stripe, Paypal, Apple Pay and Afterpay terms and conditions which are available on
              their website. (b) You acknowledge and agree that where a request for the payment of
              the Subscription Fee is returned or denied, for whatever reason, by your financial
              institution or is unpaid by you for any other reason, then you are liable for any
              costs, including banking fees and charges, associated with the Subscription Fee. (c)
              You agree and acknowledge that WITHDRAWN APP - FZCO can vary the Subscription Fee at
              any time and that the varied Subscription Fee will come into effect following the
              conclusion of the existing Subscription Period.
            </Typography>
            <strong>Refund Policy</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) WITHDRAWN APP - FZCO will only provide you with a refund of the Subscription Fee
              in the event they are unable to continue to provide the Services or if the manager of
              WITHDRAWN APP - FZCO makes a decision, at its absolute discretion, that it is
              reasonable to do so under the circumstances. Where this occurs, the refund will be in
              the proportional amount of the Subscription Fee that remains unused by the Member
              (Refund). (b) Any benefits set out in this Terms and Conditions may apply in addition
              to consumer&apos;s rights under International Consumer Law.
            </Typography>
            <strong>Copyright and Intellectual Property</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) The Application, the Services and all of the related products of WITHDRAWN APP -
              FZCO are subject to copyright. The material on the Application is protected by
              copyright under International law and through international treaties. Unless otherwise
              indicated, all rights (including copyright) in the Services and compilation of the
              Application (including but not limited to text, graphics, logos, button icons, video
              images, audio clips, Application code, scripts, design elements and interactive
              features) or the Services are owned or controlled for these purposes, and are reserved
              by WITHDRAWN APP - FZCO or its contributors. (b) All trademarks, service marks and
              trade names are owned, registered and/or licensed by WITHDRAWN APP - FZCO, who grants
              to you a worldwide, non-exclusive, royalty- free, revocable license whilst you are a
              Member to: (i) use the Application pursuant to the Terms; (ii) copy and store the
              Application and the material contained in the Application in your device&apos;s cache
              memory; and (iii) print pages from the Application for your own personal and
              non-commercial use. WITHDRAWN APP - FZCO does not grant you any other rights
              whatsoever in relation to the Application or the Services. All other rights are
              expressly reserved by WITHDRAWN APP - FZCO. (c) WITHDRAWN APP - FZCO retains all
              rights, title and interest in and to the Application and all related Services. Nothing
              you do on or in relation to the Application will transfer any: (i) business name,
              trading name, domain name, trade mark, industrial design, patent, registered design or
              copyright, or (ii) a right to use or exploit a business name, trading name, domain
              name, trade mark or industrial design, or (iii) a thing, system or process that is the
              subject of a patent, registered design or copyright (or an adaptation or modification
              of such a thing, system or process), to you. (d) You may not, without the prior
              written permission of WITHDRAWN APP - FZCO and the permission of any other relevant
              rights owners: broadcast, republish, up-load to a third party, transmit, post,
              distribute, show or play in public, adapt or change in any way the Services or third
              party Services for any purpose, unless otherwise provided by these Terms.
            </Typography>
            <strong>Privacy</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              WITHDRAWN APP - FZCO takes your privacy seriously and any information provided through
              your use of the Application and/or Services are subject to WITHDRAWN APP - FZCO&apos;s
              Privacy Policy, which is available.
            </Typography>
            <strong>General Disclaimer</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) Nothing in the Terms limits or excludes any guarantees, warranties,
              representations or conditions implied or imposed by law, including the International
              Consumer Law (or any liability under them) which by law may not be limited or
              excluded. (b) Subject to this clause, and to the extent permitted by law: (i) all
              terms, guarantees, warranties, representations or conditions which are not expressly
              stated in the Terms are excluded; and (ii) WITHDRAWN APP - FZCO will not be liable for
              any special, indirect or consequential loss or damage (unless such loss or damage is
              reasonably foreseeable resulting from our failure to meet an applicable Consumer
              Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in
              connection with the Services or these Terms (including as a result of not being able
              to use the Services or the late supply of the Services), whether at common law, under
              contract, tort (including negligence), in equity, pursuant to statute or otherwise.
              (c) Use of the Application and the Services is at your own risk. Everything on the
              Application and the Services is provided to you &quot;as is&quot; and &quot;as
              available&quot; without warranty or condition of any kind. None of the affiliates,
              directors, officers, employees, agents, contributors and licensors of WITHDRAWN APP -
              FZCO make any express or implied representation or warranty about the Services or any
              products or Services (including the products or Services of WITHDRAWN APP - FZCO)
              referred to on the Application, includes (but is not restricted to) loss or damage you
              might suffer as a result of any of the following: (i) failure of performance, error,
              omission, interruption, deletion, defect, failure to correct defects, delay in
              operation or transmission, computer virus or other harmful component, loss of data,
              communication line failure, unlawful third party conduct, or theft, destruction,
              alteration or unauthorized access to records; (ii) the accuracy, suitability or
              currency of any information on the Application, the Services, or any of its Services
              related products (including third party material and advertisements on the
              Application); (iii) costs incurred as a result of you using the Application, the
              Services or any of the products of WITHDRAWN APP - FZCO; (iv) the Services or
              operation in respect to links which are provided for your convenience.
            </Typography>
            <strong> Limitation of Liability</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) WITHDRAWN APP - FZCO&apos;s total liability arising out of or in connection with
              the Services or these Terms, however arising, including under contract, tort
              (including negligence), in equity, under statute or otherwise, will not exceed the
              resupply of the Services to you. (b) You expressly understand and agree that WITHDRAWN
              APP - FZCO, its affiliates, employees, agents, contributors and licensors will not be
              liable to you for any direct, indirect, incidental, special consequential or exemplary
              damages which may be incurred by you, however caused and under any theory of
              liability. This will include, but is not limited to, any loss of profit (whether
              incurred directly or indirectly), any loss of goodwill or business reputation and any
              other intangible loss.
            </Typography>
            <strong> Competitors</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              If you are in the business of providing similar Services for the purpose of providing
              them to users for a commercial gain, whether business users or domestic users, then
              you are a competitor of WITHDRAWN APP - FZCO. Competitors are not permitted to use or
              access any information or content on our Application. If you breach this provision,
              WITHDRAWN APP - FZCO will hold you fully responsible for any loss that we may sustain
              and hold you accountable for all profits that you might make from such a breach.
            </Typography>
            <strong> Termination of Contract</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) The Terms will continue to apply until terminated by either you or by WITHDRAWN
              APP - FZCO as set out below. (b) If you want to terminate the Terms, you may do so by:
              (i) not renewing the Subscription prior to the end of the Subscription Period; (ii)
              providing WITHDRAWN APP - FZCO with 90 days&apos; notice of your intention to
              terminate; and (iii) closing your accounts for all of the services which you use,
              where WITHDRAWN APP - FZCO has made this option available to you. Your notice should
              be sent, in writing, to WITHDRAWN APP - FZCO via the &apos;Contact Us&apos; link on
              our homepage. (c) WITHDRAWN APP - FZCO may at any time, terminate the Terms with you
              if: (i) you do not renew the Subscription at the end of the Subscription Period; (ii)
              you have breached any provision of the Terms or intend to breach any provision; (iii)
              WITHDRAWN APP - FZCO is required to do so by law; (iv) the provision of the Services
              to you by WITHDRAWN APP - FZCO is, in the opinion of WITHDRAWN APP - FZCO, no longer
              commercially viable. (d) Subject to local applicable laws, WITHDRAWN APP - FZCO
              reserves the right to discontinue or cancel your membership at any time and may
              suspend or deny, in its sole discretion, your access to all or any portion of the
              Application or the Services without notice if you breach any provision of the Terms or
              any applicable law or if your conduct impacts WITHDRAWN APP - FZCO&apos;s name or
              reputation or violates the rights of those of another party.
            </Typography>
            <strong> Indemnity</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) You agree to indemnify WITHDRAWN APP - FZCO, its affiliates, employees, agents,
              contributors, third party content providers and licensors from and against: (i) all
              actions, suits, claims, demands, liabilities, costs, expenses, loss and damage
              (including legal fees on a full indemnity basis) incurred, suffered or arising out of
              or in connection with Your Content; (ii) any direct or indirect consequences of you
              accessing, using or transacting on the Application or attempts to do so; and/or (iii)
              any breach of the Terms.
            </Typography>
            <strong> Dispute Resolution</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              (a) Compulsory: If a dispute arises out of or relates to the Terms, either party may
              not commence any Tribunal or Court proceedings in relation to the dispute, unless the
              following clauses have been complied with (except where urgent interlocutory relief is
              sought). (b) Notice: A party to the Terms claiming a dispute (Dispute) has arisen
              under the Terms, must give written notice to the other party detailing the nature of
              the dispute, the desired outcome and the action required to settle the Dispute. (c)
              Resolution: On receipt of that notice (Notice) by that other party, the parties to the
              Terms (Parties) must: (i) Within 30 days of the Notice endeavor in good faith to
              resolve the Dispute expeditiously by negotiation or such other means upon which they
              may mutually agree; (ii) If for any reason whatsoever, 30 days after the date of the
              Notice, the Dispute has not been resolved, the Parties must either agree upon
              selection of a mediator or request that an appropriate mediator be appointed by the
              President of the International Mediation Institute or his or her nominee; (iii) The
              Parties are equally liable for the fees and reasonable expenses of a mediator and the
              cost of the venue of the mediation and without limiting the foregoing undertake to pay
              any amounts requested by the mediator as a pre-condition to the mediation commencing.
              The Parties must each pay their own costs associated with the mediation; (iv) The
              mediation will be held in Dubai, UAE. (d) Confidential: All communications concerning
              negotiations made by the Parties arising out of and in connection with this dispute
              resolution clause are confidential and to the extent possible, must be treated as
              &quot;without prejudice&quot; negotiations for the purpose of applicable laws of
              evidence. (e) Termination of Mediation: If 2 months have elapsed after the start of a
              mediation of the Dispute and the Dispute has not been resolved, either Party may ask
              the mediator to terminate the mediation and the mediator must do so.
            </Typography>
            <strong> Governing Law</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              The Terms are governed by International Law. Any dispute, controversy, proceeding or
              claim of whatever nature arising out of or in any way relating to the Terms and the
              rights created hereby shall be governed, interpreted and construed by, under and
              pursuant to International Law, without reference to conflict of law principles,
              notwithstanding mandatory rules. The validity of this governing law clause is not
              contested. The Terms shall be binding to the benefit of the parties hereto and their
              successors and assigns.
            </Typography>
            <strong> Severance</strong>
            <Typography fontSize="14px" mt={'5px'} component="div" paragraph>
              If any part of these Terms is found to be void or unenforceable by a Court of
              competent jurisdiction, that part shall be severed and the rest of the Terms shall
              remain in force.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TermsAndConditions;
