import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Home } from '../../assets/home.svg';
import { ReactComponent as HomeFilled } from '../../assets/homeFilled.svg';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as SearchFilled } from '../../assets/searchFilled.svg';
import { ReactComponent as Watchlist } from '../../assets/watchlist.svg';
import { ReactComponent as WatchlistFilled } from '../../assets/watchlistFilled.svg';
import { ReactComponent as Profile } from '../../assets/profile.svg';
import { ReactComponent as ProfileFilled } from '../../assets/profileFilled.svg';
import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
import { Store } from 'context/store';

const Drawer: FC = () => {
  const store = Store();
  const nav = useNavigate();
  // const [animateWatchlist, setAnimateWatchlist] = useState(false);
  // const [animateSearch, setAnimateSearch] = useState(false);

  // useEffect(() => {
  //   if (window.location.pathname === '/search') {
  //     setAnimateWatchlist(true);
  //   }
  //   if (window.location.pathname === '/watchlist') {
  //     setAnimateSearch(true);
  //   }
  // }, []);
  return (
    <Box
      position="fixed"
      width="100%"
      bottom={0}
      display="flex"
      alignItems="center"
      height="15%"
      justifyContent="center"
      sx={{
        background: 'linear-gradient(to bottom, rgba(1, 1, 1, 0) 0%, black 50%)',
      }}
    >
      <Box
        alignItems="baseline"
        display="flex"
        sx={{
          '@media (max-width: 500px)': {
            width: '100%',
            justifyContent: 'space-around',
            padding: '8px 26px',
          },
        }}
        padding="20px 50px"
        gap="50px"
        bgcolor="black"
        borderRadius="12px"
      >
        {/* home icon */}
        <Box
          onClick={() => {
            nav('/home');
          }}
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="12px"
          sx={{
            cursor: 'pointer',
            '@media (max-width: 500px)': {
              gap: '2px',
            },
          }}
        >
          <Box
            width={'32px'}
            sx={{
              '@media (max-width: 500px)': {
                width: '24px',
              },
            }}
          >
            {window.location.pathname !== '/home' ? (
              <Home width={'100%'} />
            ) : (
              <HomeFilled width={'100%'} />
            )}
          </Box>
          <Typography
            sx={{
              opacity: window.location.pathname !== '/home' ? 0.4 : 1,
              '@media (max-width: 500px)': {
                fontSize: '12px',
              },
            }}
            fontWeight={600}
            color="white"
          >
            Home
          </Typography>
        </Box>
        {/* Search icon */}

        <Box
          sx={{
            cursor: 'pointer',
            '@media (max-width: 500px)': {
              gap: '2px',
            },
          }}
          onClick={() => {
            nav('/search');
          }}
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="12px"
        >
          <Box
            width={'33px'}
            sx={{
              '@media (max-width: 500px)': {
                width: '24px',
              },
            }}
          >
            {!window.location.pathname.includes('search' || 'results') ? (
              <Search width={'100%'} />
            ) : (
              <SearchFilled width={'100%'} />
            )}
          </Box>
          <Typography
            sx={{
              opacity: !window.location.pathname.includes('search' || 'results') ? 0.4 : 1,
              '@media (max-width: 500px)': {
                fontSize: '12px',
              },
            }}
            fontWeight={600}
            color="white"
          >
            Search
          </Typography>
        </Box>
        {/* Watchlist icon */}

        {/* <Box
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            nav('/search');
          }}
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="12px"
        >
          {window.location.pathname !== '/watchlist' ? <Watchlist /> : <WatchlistFilled />}
          <Typography
            sx={{
              opacity: window.location.pathname !== '/watchlist' ? 0.4 : 1,
              '@media (max-width: 500px)': {
                fontSize: '12px',
              },
            }}
            fontWeight={600}
            color="white"
          >
            Watchlist
          </Typography>
        </Box> */}
        <Box
          sx={{
            cursor: 'pointer',
            '@media (max-width: 500px)': {
              gap: '2px',
            },
          }}
          onClick={() => {
            nav('/watchlist');
          }}
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="12px"
        >
          <Box position="relative">
            <Box
              top="-5px"
              right="-8px"
              position="absolute"
              justifyContent="center"
              alignItems="center"
              display={store?.watchlistCounter ? 'flex' : 'none'}
              bgcolor="red"
              borderRadius="50%"
              width="15px"
              height="15px"
              padding={'9px'}
            >
              <Typography color="white" fontSize="11px">
                {store?.watchlistCounter}
              </Typography>
            </Box>
            <Box
              width={'33px'}
              sx={{
                '@media (max-width: 500px)': {
                  width: '24px',
                },
              }}
            >
              {window.location.pathname !== '/watchlist' ? (
                <Watchlist width={'100%'} />
              ) : (
                <WatchlistFilled width={'100%'} />
              )}
            </Box>
          </Box>
          <Typography
            sx={{
              opacity: window.location.pathname !== '/watchlist' ? 0.4 : 1,
              '@media (max-width: 500px)': {
                fontSize: '12px',
              },
            }}
            fontWeight={600}
            color="white"
          >
            Watchlist
          </Typography>
        </Box>
        {/* profile seeting icon */}

        <Box
          sx={{
            cursor: 'pointer',
            '@media (max-width: 500px)': {
              gap: '2px',
            },
          }}
          onClick={() => {
            nav('/profile');
          }}
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="12px"
        >
          <Box
            width={'34px'}
            sx={{
              '@media (max-width: 500px)': {
                width: '24px',
              },
            }}
          >
            {window.location.pathname !== '/profile' ? (
              <Profile width={'100%'} />
            ) : (
              <ProfileFilled width={'100%'} />
            )}
          </Box>
          <Typography
            sx={{
              opacity: window.location.pathname !== '/profileSetting' ? 0.4 : 1,
              '@media (max-width: 500px)': {
                fontSize: '12px',
              },
            }}
            fontWeight={600}
            color="white"
          >
            Profile
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Drawer;
