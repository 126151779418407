import { Box, Radio, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as Check } from '../assets/checkCircle.svg';
import { ReactComponent as RadioUnchecked } from '../assets/radiouncheckked.svg';
// import { ReactComponent as SmallRadioUnchecked } from '../assets/uncheckedSmall.svg';
import { ReactComponent as RadioChecked } from '../assets/checkedRadio.svg';
// import { ReactComponent as SmallRadioChecked } from '../assets/checkedSmall.svg';

interface IMobcardProps {
  item: any;
  setSelected: (val: any) => void;
  selected: { variant: string; price: number | null };
}

const MobilePriceCard: FC<IMobcardProps> = ({ item, selected, setSelected }) => {
  // const [value, setValue] = React.useState('');

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   setValue((event.target as HTMLInputElement).value);
  // };

  return (
    <Box
      display="none"
      gap="15px"
      flexBasis="30%"
      flexDirection="column"
      // border={item.variant === 'Basic' ? '1px solid #EFF0F6' : 'unset'}
      borderRadius="24px"
      padding="40px"
      sx={{
        // outline: '1px solid transparent',
        // '&:hover': {
        //   background: 'linear-gradient(to right, #2A2929, black 64%)',
        //   // border: '1px solid #EFF0F6',
        //   outline: '1px solid rgba(255, 255, 255, 0.2)',

        //   transform: 'scale(1.01)',
        //   transition: '0.5s',
        // },
        '@media (max-width: 500px)': {
          flexBasis: 'unset',
          border: '2px solid #2D2D2D',
          minWidth: '300px',
          padding: '20px',
          margin: '10px',
          display: 'flex',
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="17px">
        <Box display="flex" alignItems="start" justifyContent={'space-between'}>
          <Box display="flex" alignItems="center" gap="12px">
            <Box
              borderRadius="16px"
              padding="22px"
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                cursor: 'pointer',
              }}
            >
              {item.logo}
            </Box>
            <Box>
              <Typography
                textTransform={'capitalize'}
                fontWeight="bold"
                color="white"
                fontSize="24px"
              >
                {item?.variant}
              </Typography>
              <Typography fontWeight="regular" color="white" fontSize="18px">
                For Everyone
              </Typography>
            </Box>
          </Box>
          <Radio
            checked={selected.variant === item.variant}
            onChange={(e) => {
              setSelected({ variant: e.target.value, price: item.price });
            }}
            value={item.variant}
            icon={<RadioUnchecked />}
            checkedIcon={<RadioChecked />}
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography fontWeight="bold" color="white" fontSize="32px">
          {item.variant === 'Free' ? item?.freeTraial : '$' + String(item?.price)}
          <Typography
            ml="10px"
            component="span"
            fontWeight="semiBold"
            color="white"
            fontSize="20px"
          >
            {item.variant === 'Free' ? '/free trial' : '/per month'}{' '}
          </Typography>
        </Typography>
        <Box display={item.variant !== 'Free' ? 'flex' : 'none'} flexDirection={'column'}>
          {/* <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              sx={{
                color: 'white',
                gap: '12px',
                justifyContent: 'space-between',
              }}
              value="Monthly"
              control={<Radio icon={<SmallRadioUnchecked />} checkedIcon={<SmallRadioChecked />} />}
              label="Monthly"
              labelPlacement="start"
            />
            <FormControlLabel
              sx={{
                gap: '12px',
                color: 'white',
              }}
              value="Annually"
              control={<Radio icon={<SmallRadioUnchecked />} checkedIcon={<SmallRadioChecked />} />}
              label="Annually"
              labelPlacement="start"
            />
          </RadioGroup> */}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="24px">
        <Box position="relative" display="grid" gap="16px" gridTemplateColumns={'1fr 1fr'}>
          <Box
            sx={{
              background: 'linear-gradient(to bottom, rgba(1, 1, 1, 0) 0%, black 100%)',
              '@media (max-width: 500px)': {
                display: 'none',
              },
            }}
            position="absolute"
            width="100%"
            height="64%"
            bottom={0}
          ></Box>
          {item?.included.map((included: any, index: number) => {
            return (
              <Box key={index} display="flex" gap="14px">
                <Box>
                  <Check />
                </Box>
                <Typography
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                    },
                  }}
                  fontWeight="regular"
                  color="white"
                  fontSize="18px"
                >
                  {included}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default MobilePriceCard;
