import React, { FC, useState } from 'react';
import { Box, Button, Checkbox, InputAdornment, TextField, Typography } from '@mui/material';
import { ReactComponent as Google } from 'assets/google.svg';
import { ReactComponent as Apple } from 'assets/apple.svg';
import { ReactComponent as Facebook } from 'assets/facebook.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';

import { style } from './login.unAuth';
import { useNavigate } from 'react-router-dom';

import { motion, AnimatePresence } from 'framer-motion';
import request from 'api/request';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RequestSubmitted from 'components/popups/requestSubmitted';

const variants = {
  hidden: { opacity: 0.4 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.2,
      ease: 'linear',
    },
  },
  exit: {
    y: 100,
    transition: {
      delay: 0,
      duration: 0.2,
      ease: 'linear',
    },
  },
};

const Register: FC = () => {
  const nav = useNavigate();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [fail, setFail] = useState<boolean>(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'user',
  });
  const [show, setShow] = useState(false);

  const handleChange = (name: string, value: string): void => {
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignup = (): void => {
    if (!check) {
      setFail(true);
      setMessage('Please Agree to terms and conditions');
      setOpenToast(true);
    } else {
      const body = user;
      request('post', '/users', body)
        .then((res) => {
          console.log(res);
          setOpenToast(true);
          setMessage('Verification Email Sent On Your Email Address.');
          setUser({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            role: 'user',
          });
        })
        .catch((err) => {
          setFail(!err.response.data.success);
          setMessage(err.response.data.message);
          setOpenToast(true);
        });
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
      }}
    >
      <>
        <Box
          gap="50px"
          display="flex"
          flexDirection="column"
          padding="50px"
          alignItems="center"
          border="1px solid #FFFFFF"
          borderRadius="12px"
          width="30%"
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
              border: 'none',
              padding: '20px',
            },
          }}
        >
          <Box
            sx={{
              '@media (max-width: 500px)': {
                width: '146px',
              },
            }}
          >
            <Logo width="100%" />
          </Box>
          <AnimatePresence>
            <motion.div
              initial={'hidden'}
              animate={'visible'}
              exit={'exit'}
              variants={variants}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '50px',
                width: '100%',
                height: '100%',
              }}
            >
              <Box display="flex" gap="20px" flexDirection="column">
                <Box
                  sx={{
                    '@media (max-width: 500px)': {
                      flexDirection: 'column',
                    },
                  }}
                  display="flex"
                  gap="20px"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="column" gap="8px" width="100%">
                    <Typography fontSize="16px" color="white">
                      First Name
                    </Typography>
                    <TextField
                      sx={style}
                      InputProps={{
                        style: {
                          borderRadius: '12px',
                          color: 'white',
                        },
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Write"
                      fullWidth
                      name="firstName"
                      value={user?.firstName}
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px" width="100%">
                    <Typography fontSize="16px" color="white">
                      Last Name
                    </Typography>
                    <TextField
                      sx={style}
                      InputProps={{
                        style: {
                          borderRadius: '12px',
                          color: 'white',
                        },
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Write"
                      fullWidth
                      name="lastName"
                      value={user?.lastName}
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="8px" width="100%">
                  <Typography fontSize="16px" color="white">
                    Email
                  </Typography>
                  <TextField
                    sx={style}
                    InputProps={{
                      style: {
                        borderRadius: '12px',
                        color: 'white',
                      },
                    }}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter your email address"
                    fullWidth
                    name="email"
                    value={user?.email}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap="8px" width="100%">
                  <Typography color="white">Password</Typography>
                  <TextField
                    type={show ? 'text' : 'password'}
                    autoComplete="new-password"
                    sx={style}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          {show ? (
                            <Box
                              onClick={() => {
                                setShow(!show);
                              }}
                            >
                              <VisibilityIcon htmlColor="white" />
                            </Box>
                          ) : (
                            <Box
                              onClick={() => {
                                setShow(!show);
                              }}
                            >
                              <VisibilityOffIcon htmlColor="white" />
                            </Box>
                          )}
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '12px',
                        color: 'white',
                        paddingRight: '32px',
                      },
                    }}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="*******"
                    fullWidth
                    name="password"
                    value={user?.password}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Checkbox
                    checked={check}
                    onChange={(e) => {
                      setCheck(e.target.checked);
                    }}
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                      },
                    }}
                    color={'white'}
                  >
                    Accept{' '}
                    <span
                      onClick={() => {
                        nav('/terms-and-conditions');
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      Terms and Conditions
                    </span>{' '}
                    &{' '}
                    <span
                      onClick={() => {
                        nav('/privacy-policy');
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      Privacy Policy
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="20px" height="100%">
                <Button
                  onClick={handleSignup}
                  fullWidth
                  sx={{
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                    borderRadius: '12px',
                    backgroundColor: 'white',
                    textTransform: 'unset',
                    color: 'black',
                    padding: '12px 24px',
                  }}
                >
                  Create
                </Button>
                <Box
                  display="flex"
                  sx={{
                    '@media (max-width: 500px)': {
                      display: 'none',
                    },
                  }}
                  justifyContent="center"
                >
                  <Typography textAlign="center" margin="12px 0" color="white">
                    I have an account?{' '}
                    <Typography
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        nav('/login');
                      }}
                      component="span"
                    >
                      Login
                    </Typography>
                  </Typography>
                </Box>
                <Box display="flex" gap="10px" alignItems="center" justifyContent="center">
                  <Box borderBottom="1px solid white" width="30%" />
                  <Typography fontSize="12px" color="white">
                    OR
                  </Typography>
                  <Box borderBottom="1px solid white" width="30%" />
                </Box>
                <Box
                  display="flex"
                  sx={{
                    '@media (max-width: 500px)': {
                      display: 'none',
                    },
                  }}
                  gap="20px"
                  justifyContent="center"
                >
                  <Button
                    sx={{
                      borderRadius: '12px',
                      border: '1px solid white',
                      textTransform: 'unset',
                      color: 'white',
                      padding: '16px',
                      background: 'transparent',
                    }}
                  >
                    <Google />
                  </Button>
                  <Button
                    sx={{
                      borderRadius: '12px',
                      border: '1px solid white',
                      textTransform: 'unset',
                      color: 'white',
                      padding: '16px',
                      background: 'transparent',
                    }}
                  >
                    <Apple />
                  </Button>
                  <Button
                    sx={{
                      borderRadius: '12px',
                      border: '1px solid white',
                      textTransform: 'unset',
                      color: 'white',
                      padding: '16px',
                      background: 'transparent',
                    }}
                  >
                    <Facebook />
                  </Button>
                </Box>

                <Box
                  display="none"
                  sx={{
                    '@media (max-width: 500px)': {
                      display: 'flex',
                    },
                  }}
                  gap="20px"
                  justifyContent="center"
                >
                  <Box>
                    <Google width={32} height={32} />
                  </Box>
                  <Box>
                    <Apple width={32} height={32} />
                  </Box>
                  <Box>
                    <Facebook width={32} height={32} />
                  </Box>
                </Box>
                <Box
                  mt="auto"
                  display="none"
                  sx={{
                    '@media (max-width: 500px)': {
                      display: 'block',
                    },
                  }}
                  justifyContent="center"
                >
                  <Typography textAlign="center" color="white">
                    I have an account?{' '}
                    <Typography
                      mt="10px"
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        nav('/login');
                      }}
                    >
                      Login
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence>
        </Box>
        <RequestSubmitted
          fail={fail}
          message={message}
          open={openToast}
          handleClose={() => {
            setOpenToast(false);
          }}
        />
      </>
    </Box>
  );
};

export default Register;
