import React, { FC, useState } from 'react';
import { Box, Button, Modal, Typography, TextField, InputAdornment } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '30%',
  '@media (max-width: 500px)': {
    width: '100%',
    height: '100%',
    border: 'none',
    gap: '32px',
    padding: '20px',
    borderRadius: '0px',
  },
  bgcolor: 'black',
  borderRadius: '20px',
  border: '1px solid white',
  boxShadow: 24,
  p: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  '&:focus-visible': {
    outline: 'none',
  },
};
const inputStyle = {
  input: {
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

interface IAddNote {
  open: boolean;
  handleClose: () => void;
  handleChange: (name: string, value: string) => void;
  userId?: string;
  handleUpdatePassword: () => void;
  updateUserPassword: {
    old: string;
    new: string;
    reEnter: string;
  };
}
const AddNote: FC<IAddNote> = ({
  open,
  handleClose,
  updateUserPassword,
  userId,
  handleChange,
  handleUpdatePassword,
}) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
          <Typography
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '22px',
                textAlign: 'left',
              },
            }}
            fontWeight="bold"
            fontSize="32px"
            color="white"
          >
            Edit Profile
          </Typography>
          {/* <Box display="flex" justifyContent="center">
            <Typography width="60%" fontWeight="regular" color="white">
              Lorem ipsum dolor sit amet doloroli
            </Typography>
          </Box> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          sx={{
            '@media (max-width: 500px)': {
              gap: '20px',
            },
          }}
          width="100%"
        >
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography color="white">Old Password</Typography>
            <TextField
              type={show ? 'text' : 'password'}
              autoComplete="new-password"
              sx={inputStyle}
              inputProps={{
                style: {
                  padding: '16px 0px 16px 32px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {show ? (
                      <Box
                        onClick={() => {
                          setShow(!show);
                        }}
                      >
                        <VisibilityIcon htmlColor="white" />
                      </Box>
                    ) : (
                      <Box
                        onClick={() => {
                          setShow(!show);
                        }}
                      >
                        <VisibilityOffIcon htmlColor="white" />
                      </Box>
                    )}
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '12px',
                  color: 'white',
                  paddingRight: '32px',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="*******"
              fullWidth
              name="old"
              value={updateUserPassword.old}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography color="white">New Password</Typography>
            <TextField
              type={show2 ? 'text' : 'password'}
              autoComplete="new-password"
              sx={inputStyle}
              inputProps={{
                style: {
                  padding: '16px 0px 16px 32px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {show2 ? (
                      <Box
                        onClick={() => {
                          setShow2(!show2);
                        }}
                      >
                        <VisibilityIcon htmlColor="white" />
                      </Box>
                    ) : (
                      <Box
                        onClick={() => {
                          setShow2(!show2);
                        }}
                      >
                        <VisibilityOffIcon htmlColor="white" />
                      </Box>
                    )}
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '12px',
                  color: 'white',
                  paddingRight: '32px',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="*******"
              fullWidth
              name="new"
              value={updateUserPassword.new}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography color="white">Re Enter New Password</Typography>
            <TextField
              type={show3 ? 'text' : 'password'}
              autoComplete="new-password"
              sx={inputStyle}
              inputProps={{
                style: {
                  padding: '16px 0px 16px 32px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {show3 ? (
                      <Box
                        onClick={() => {
                          setShow3(!show3);
                        }}
                      >
                        <VisibilityIcon htmlColor="white" />
                      </Box>
                    ) : (
                      <Box
                        onClick={() => {
                          setShow3(!show3);
                        }}
                      >
                        <VisibilityOffIcon htmlColor="white" />
                      </Box>
                    )}
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '12px',
                  color: 'white',
                  paddingRight: '32px',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="*******"
              fullWidth
              name="reEnter"
              value={updateUserPassword.reEnter}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            '@media (max-width: 500px)': {
              mt: 'auto',
            },
          }}
          width="100%"
          display="flex"
          flexDirection="column"
          gap={'10px'}
        >
          <Button
            fullWidth
            onClick={handleUpdatePassword}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Change
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'black',
              },
              borderRadius: '12px',
              backgroundColor: 'black',
              textTransform: 'unset',
              color: 'white',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNote;
