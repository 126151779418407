import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from 'components/common/Header';
import { ReactComponent as Logo } from '../assets/bigLogo.svg';
import { ReactComponent as WhiteNotifi } from '../assets/whiteNotification.svg';
import { ReactComponent as Filled } from '../assets/filledNotification.svg';
import { ReactComponent as Delete } from '../assets/delete.svg';
import { ReactComponent as Add } from '../assets/addNote.svg';
import { ReactComponent as Edit } from '../assets/edit.svg';
// import demo from '../assets/demo.png';
import Drawer from 'components/common/Drawer';
import { motion, AnimatePresence } from 'framer-motion';
import { variants } from 'unAuth/Login';
import ImagePicker from 'components/common/imagePicker.component';
import AddNote from 'components/popups/addNote';
import EditNote from 'components/popups/editNote';
// import DeleteNote from 'components/popups/deleteNote';
// import { toast } from 'react-toastify';
import request from 'api/request';
import { INotes, INotifications, INotificationsResponse } from 'types/user';
import moment from 'moment';
import toast from 'react-hot-toast';
import { ReactComponent as Back } from '../assets/back.svg';
import RequestSubmitted from 'components/popups/requestSubmitted';

// const notificationArray = [
//   {
//     isSeen: false,
//     title: 'title1',
//     _id: '6667721e57d51b74b09e19bf',
//     note: [
//       {
//         productId: 13925562,
//         message: 'this is note message',
//         date: '2023-06-01T00:00:00.000+00:00',
//       },
//       {
//         productId: 13925562,
//         message: 'this is note message 2.',
//         date: '2023-06-01T00:00:00.000+00:00',
//       },
//     ],
//     propertyID: 13925562,
//     status: 'FOR SALE',
//     createdAt: '2024-06-10T21:37:34.140Z',
//     updatedAt: '2024-06-10T21:37:34.140Z',
//   },
//   {
//     isSeen: false,
//     title: 'title2',
//     _id: '6667721e57d51b74b09e19ba',
//     note: [
//       {
//         productId: 13925561,
//         message: 'this is another message',
//         date: '2023-06-01T00:00:00.000+00:00',
//       },
//       {
//         productId: 13925561,
//         message: 'this is another message 2.',
//         date: '2023-06-01T00:00:00.000+00:00',
//       },
//     ],
//     propertyID: 13925561,
//     status: 'FOR SALE',
//     createdAt: '2024-06-10T21:37:34.140Z',
//     updatedAt: '2024-06-10T21:37:34.140Z',
//   },
// ];

const Home: FC = () => {
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  // const [deleteNote, setDeleteNote] = useState(false);
  const userJson = localStorage.getItem('user');
  const parsedUser = userJson ? JSON.parse(userJson) : undefined;
  const [notifications, setNotifications] = useState<INotifications[]>();
  const [selectedNotifications, setSelectedNotifications] = useState<INotifications | null>();
  const [note, setNote] = useState('');
  const [selectedNote, setSelectedNote] = useState<INotes>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const handleCloseNote = (): void => {
    setAddNote(false);
    setEditNote(false);
    // setDeleteNote(false);
  };
  console.log(selectedNote, 'selectedNote');
  const handleOpenNote = (item: INotes): void => {
    setAddNote(true);
    setSelectedNote(item);
  };

  const handleEditNote = (note: INotes): void => {
    setEditNote(true);
    setSelectedNote(note);
  };
  const handleDeleteNotification = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('delete', `/notification/${selectedNotifications?._id}`)
      .then(() => {
        getAllNotifications();
        setOpenToast(true);
        setMessage('Notification Deleted Successfully!');
      })
      .catch((err) => {
        console.log('🚀 ~ handleDeleteNotification ~ err:', err);
        toast.error(<b>There was an error. Please try again later.</b>);
      });
  };

  const handleNotification = (): void => {
    setOpenToast(true);
    setMessage('Note will be removed from Notifications');
  };

  const getAllNotifications = (): void => {
    request<INotificationsResponse>(
      'get',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/notification/${parsedUser._id}`,
    )
      .then((res) => {
        setNotifications(res.data.notification);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getNotesById = (): void => {
  //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //   request('get', `/watchlist/notes/${parsedUser._id}`)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    getAllNotifications();
    // getNotesById();
  }, []);
  return (
    <>
      <Header menu />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        height="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <AnimatePresence>
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={variants}
            className="home_container"
          >
            <Box
              sx={{
                '@media (max-width: 500px)': {
                  display: 'none',
                },
              }}
            >
              <Logo />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="5px"
              textAlign="center"
              sx={{
                '@media (max-width: 500px)': {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              }}
            >
              <Box
                display="flex"
                gap="5px"
                flexDirection="column"
                justifyContent="center"
                sx={{
                  '@media (max-width: 500px)': {
                    alignItems: 'flex-start',
                  },
                }}
              >
                <Typography
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '20px',
                    },
                  }}
                  fontWeight="bold"
                  fontSize="32px"
                  color="white"
                  textTransform="capitalize"
                >
                  Welcome {parsedUser?.firstName}
                </Typography>
                <Typography
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                      textAlign: 'left',
                    },
                  }}
                  fontWeight="regular"
                  color="white"
                >
                  Information to all listings On-Market, Expired or Withdrawn
                </Typography>
              </Box>
              <Box
                display="none"
                sx={{
                  '@media (max-width: 500px)': {
                    display: 'block',
                  },
                }}
              >
                <ImagePicker
                  height="80px"
                  user={parsedUser}
                  setOpenToast={setOpenToast}
                  setMessage={setMessage}
                />
              </Box>
            </Box>

            <Box
              padding="30px"
              display="flex"
              flexDirection="column"
              gap="16px"
              borderRadius="12px"
              borderRight="1px solid white"
              borderTop="1px solid white"
              borderLeft="1px solid white"
              height="100%"
              width="100%"
              overflow="auto"
              sx={{
                '@media (max-width: 500px)': {
                  padding: 'unset',
                  border: 'none',
                },
              }}
            >
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flex={'row'}
              >
                <Typography
                  fontSize="24px"
                  fontWeight="600"
                  color="white"
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '20px',
                    },
                  }}
                >
                  Notifications
                </Typography>
                <Typography
                  fontSize="18px"
                  fontWeight="600"
                  color="white"
                  // width={'15%'}
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                    },
                  }}
                  textAlign={'center'}
                >
                  Status
                </Typography>
              </Box>
              <Box
                overflow="auto"
                display="flex"
                flexDirection="column"
                gap="16px"
                sx={{
                  paddingBottom: '12%',
                }}
              >
                {notifications?.length ? (
                  notifications?.reverse()?.map((item) => {
                    return selectedNotifications?._id === item._id ? (
                      <Box
                        key={item._id}
                        display="flex"
                        gap="12px"
                        padding="20px"
                        flexDirection="column"
                        sx={{
                          background:
                            'linear-gradient(to right, rgba(100, 100, 100, 0.2), black 100%)',
                        }}
                      >
                        <Box
                          width="fit-content"
                          mb={'15px'}
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedNotifications(null);
                          }}
                        >
                          <Back />
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex" flexDirection="column" gap="10px">
                            <Box display="flex" alignItems="center" gap="8px">
                              <WhiteNotifi />
                              <Typography fontWeight="bold" fontSize="12px" color="white">
                                You added{' '}
                                {item.notes?.length < 2
                                  ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    `${item.notes?.length} Note`
                                  : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    `${item.notes?.length} Notes`}
                              </Typography>
                            </Box>
                            <Typography fontSize="20px" fontWeight="bold" color="white">
                              {item?.propertyTitle}
                            </Typography>
                          </Box>
                          <Box display="flex" gap="12px">
                            <Delete
                              onClick={handleDeleteNotification}
                              style={{ cursor: 'pointer' }}
                            />
                            <Filled onClick={handleNotification} style={{ cursor: 'pointer' }} />
                          </Box>
                        </Box>
                        <Box display="flex" gap="16px">
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="10px"
                            alignItems="center"
                            justifyContent="center"
                            width="20%"
                            sx={{
                              '@media (max-width: 500px)': {
                                display: 'none',
                              },
                            }}
                          >
                            <Add
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleOpenNote(item.notes?.[0]);
                              }}
                            />
                            <Typography fontSize="12px" fontWeight="bold" color="white">
                              Add Note
                            </Typography>
                          </Box>
                          <Box
                            width="80%"
                            sx={{
                              '@media (max-width: 500px)': {
                                width: 'unset',
                              },
                            }}
                            overflow="auto"
                            position="relative"
                          >
                            <Box display="flex" gap="16px" overflow="auto">
                              <Box
                                sx={{
                                  background:
                                    'linear-gradient(to right, rgba(1, 1, 1, 0) 0%, black 100%)',
                                  pointerEvents: 'none',
                                }}
                                right={0}
                                position="absolute"
                                height="100%"
                                width="30%"
                              ></Box>
                              {item.notes?.map((note, index) => {
                                return (
                                  <Box
                                    key={index}
                                    display="flex"
                                    flexDirection="column"
                                    padding="15px"
                                    border="1px solid rgba(255, 255, 255, 0.1)"
                                    borderRadius="12px"
                                    width="40%"
                                    minWidth="300px"
                                    gap="10px"
                                    sx={{
                                      '@media (max-width: 500px)': {
                                        minWidth: '240px',
                                      },
                                    }}
                                  >
                                    <Typography color="#E0E0E0">{note.message}</Typography>
                                    <Box display="flex" justifyContent="space-between">
                                      <Typography fontSize="12px" fontWeight="600" color="white">
                                        Saved - {moment(note.date).startOf('day').fromNow()}
                                      </Typography>
                                      <Edit
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          handleEditNote(note);
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="none"
                          borderRadius="10px"
                          gap="10px"
                          alignItems="center"
                          padding="12px 16px"
                          justifyContent="space-between"
                          width="100%"
                          sx={{
                            background:
                              'linear-gradient(to right, rgba(100, 100, 100, 0.4), black 100%)',
                            '@media (max-width: 500px)': {
                              display: 'flex',
                            },
                          }}
                        >
                          <Typography fontSize="12px" fontWeight="bold" color="white">
                            Add Note
                          </Typography>
                          <Add
                            onClick={() => {
                              handleOpenNote(item.notes?.[0]);
                            }}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        key={item._id}
                        padding="20px"
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            background: 'rgba(255, 255, 255, 0.05)',
                          },
                          '@media (max-width: 500px)': {
                            background:
                              'linear-gradient(to right, rgba(100, 100, 100, 0.2), black 100%)',
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          sx={{
                            '@media (max-width: 500px)': {
                              flexDirection: 'column',
                              gap: '12px',
                            },
                          }}
                        >
                          <Box
                            display="flex"
                            gap="16px"
                            sx={{
                              '@media (max-width: 500px)': {
                                flexDirection: 'column',
                                width: '100%',
                              },
                            }}
                          >
                            <Box>
                              <img
                                height={100}
                                width={100}
                                style={{
                                  borderRadius: '6px',
                                  width: '100%',
                                  maxHeight: '120px',
                                  objectFit: 'cover',
                                }}
                                src={item.notes[0]?.image}
                                alt=""
                              />
                            </Box>
                            <Box display="flex" flexDirection="column" gap="10px">
                              <Box display="flex" alignItems="center" gap="8px">
                                <WhiteNotifi />
                                <Typography fontWeight="bold" fontSize="12px" color="white">
                                  You added{' '}
                                  {item.notes?.length < 2
                                    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                      `${item.notes?.length} Note`
                                    : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                      `${item.notes?.length} Notes`}
                                </Typography>
                              </Box>
                              <Typography
                                fontSize="20px"
                                fontWeight="bold"
                                color="white"
                                sx={{
                                  '@media (max-width: 500px)': {
                                    fontSize: '16px',
                                  },
                                }}
                              >
                                {item?.propertyTitle}
                              </Typography>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyItems={'center'}
                                gap={'5px'}
                              >
                                <Button
                                  sx={{
                                    textTransform: 'capitalize',
                                    // background: 'white',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    border: '.5px solid gray',
                                    borderRadius: '7px',
                                    padding: '5px 7px',
                                    '&:hover': {
                                      // background: 'white',
                                      color: 'white',
                                    },
                                  }}
                                  onClick={() => {
                                    handleOpenNote(item.notes?.[0]);
                                  }}
                                >
                                  Add Note
                                </Button>

                                <Button
                                  sx={{
                                    textTransform: 'capitalize',
                                    // background: 'white',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    border: '.5px solid gray',
                                    borderRadius: '7px',
                                    padding: '5px 7px',
                                    '&:hover': {
                                      // background: 'white',
                                      color: 'white',
                                    },
                                  }}
                                  onClick={() => {
                                    setSelectedNotifications(item);
                                  }}
                                >
                                  Show All Notes
                                </Button>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            bgcolor={
                              item.detailStatus.status === 'otmForSaleCampaignEnd'
                                ? '#00A324'
                                : item.detailStatus.status === 'sale'
                                ? '#FF5D39'
                                : '#EDA22B'
                            }
                            padding="5px 10px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="4px"
                            minWidth="10%"
                            sx={{
                              '@media (max-width: 500px)': {
                                width: '100%',
                              },
                            }}
                          >
                            <Typography color="white" fontSize="12px" fontWeight={600}>
                              {item.detailStatus.status === 'otmForSaleCampaignEnd'
                                ? 'Withdrawn'
                                : item.detailStatus.status === 'sale'
                                ? 'Sold'
                                : `${moment().diff(moment(item.detailStatus.date), 'days')} days`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography fontSize="18px" fontWeight="600" color="gray" textAlign={'center'}>
                    You have no Notifications.
                  </Typography>
                )}
              </Box>
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
      <Drawer />
      <AddNote
        open={addNote}
        handleClose={handleCloseNote}
        setNote={setNote}
        note={note}
        user={parsedUser}
        selectedNote={selectedNote}
        getAllNotifications={getAllNotifications}
        setMessage={setMessage}
        setOpenToast={setOpenToast}
      />
      <EditNote
        open={editNote}
        handleClose={handleCloseNote}
        note={selectedNote}
        user={parsedUser}
        getAllNotifications={getAllNotifications}
        setMessage={setMessage}
        setOpenToast={setOpenToast}
      />
      {/* <DeleteNote open={deleteNote} handleClose={handleCloseNote} /> */}
      <RequestSubmitted
        message={message}
        open={openToast}
        handleClose={() => {
          setOpenToast(false);
        }}
      />
    </>
  );
};

export default Home;
