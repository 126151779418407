import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Header from 'components/common/Header';
import Drawer from 'components/common/Drawer';
import Sortingbar from 'components/common/sortingbar.component';
import PropertyDetailCard from 'components/common/watchlistDetailCard.component';
import { motion, AnimatePresence } from 'framer-motion';
import { variants } from 'unAuth/Login';
import request from 'api/request';
import { ReactComponent as NoWatchlist } from 'assets/NoWatchlist.svg';
import { Store, UpdateStore } from 'context/store';
import { getAllWatchList } from 'hooks/getWatchList';
import RequestSubmitted from 'components/popups/requestSubmitted';

const Home: FC = () => {
  const store = Store();
  const updateStore = UpdateStore();
  const [view, setView] = useState<string>('listView');
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const handleTargetListView = (targetView: string): void => {
    setView(targetView);
  };

  const handleRemoveWatchlist = (targetWatchlistId: string): void => {
    request('delete', `/watchlist/${targetWatchlistId}`)
      .then((res) => {
        console.log(res);
        setOpenToast(true);
        setMessage('Removed From Watchlist');
        getAllWatchList(updateStore);
      })
      .catch((err) => {
        console.log('🚀 ~ handleRemoveWatchlist ~ err:', err);
        console.log(err);
      });
  };

  return (
    <>
      <Header menu logo />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection={'column'}
        minHeight="100vh"
        height="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Sortingbar text="YOUR WATCHLIST" handleTargetListView={handleTargetListView} view={view} />
        <AnimatePresence>
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={variants}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '2%',
              width: '82vw',
              maxHeight: '68vh',
              overflowY: 'auto',
              paddingBottom: '12%',
            }}
          >
            {store?.watchlist?.length ? (
              store?.watchlist?.map((item) => {
                console.log('🚀 ~ store?.watchlist?.map ~ item:', item);
                return (
                  <PropertyDetailCard
                    key={item.userId}
                    item={item}
                    view={view}
                    handleRemoveWatchlist={handleRemoveWatchlist}
                  />
                );
              })
            ) : (
              <Box
                width={'100%'}
                height={'50vh'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Box
                  textAlign={'center'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={'20px'}
                  flexDirection={'column'}
                  width={'25vw'}
                  sx={{
                    '@media (max-width: 500px)': {
                      width: 'unset',
                      gap: '12px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      '@media (max-width: 500px)': {
                        width: '50px',
                      },
                    }}
                  >
                    <NoWatchlist width={'100%'} />
                  </Box>
                  <Typography
                    color={'white'}
                    variant="h5"
                    fontWeight={'bold'}
                    fontFamily={'inter'}
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '20px',
                      },
                    }}
                  >
                    Watchlist Empty
                  </Typography>
                  <Typography
                    color={'white'}
                    fontFamily={'inter'}
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Discover New Favorites or Add Items to
                    <br />
                    Your watchlist for Future Reference
                  </Typography>
                </Box>
              </Box>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
      <Drawer />
      <RequestSubmitted
        message={message}
        open={openToast}
        handleClose={() => {
          setOpenToast(false);
        }}
      />
    </>
  );
};

export default Home;
