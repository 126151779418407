import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
// import PropertyImg from 'assets/propertyDetailCard/BG.png';
// import bgGrid from 'assets/bgGrid.png';
import { ReactComponent as Group26 } from 'assets/propertyDetailCard/Group26.svg';
import { ReactComponent as Group27 } from 'assets/propertyDetailCard/Group27.svg';
import { ReactComponent as Group28 } from 'assets/propertyDetailCard/Group31.svg';
import { ReactComponent as Group29 } from 'assets/propertyDetailCard/Group34.svg';
import { ReactComponent as Group30 } from 'assets/propertyDetailCard/Group38.svg';
import { ReactComponent as HeartIcon } from 'assets/heartIcon.svg';
// import { ReactComponent as WhiteHeartIcon } from 'assets/whiteHeart1.svg';
import { useNavigate } from 'react-router-dom';
import placeholderImage from 'assets/placeholder-image.png';
import request from 'api/request';
import { IProperty } from 'types/user';

// import { IProperty } from 'types/user';
import moment from 'moment';

interface PropertyDetailCardProps {
  item: IProperty;
  view: string;
  handleRemoveWatchlist: (id: string) => void;
}

// interface ItemData {
//   councilAreaId: number;
//   countryId: number;
//   isActiveProperty: boolean;
//   isBodyCorporate: boolean;
//   isUnit: boolean;
//   localityId: number;
//   postcodeId: number;
//   propertyId: number;
//   stateId: number;
//   streetId: number;
//   suggestion: string;
//   suggestionType: string;
// }

// interface IPropertyDetail {
//   isActiveProperty: boolean;
//   propertyType: string;
//   propertySubType: string;
//   propertySubTypeShort: string;
//   beds: number;
//   baths: number;
//   carSpaces: number;
//   lockUpGarages: number;
//   landArea: number;
//   isCalculatedLandArea: boolean;
//   landAreaSource: string;
//   propertyId: string;
// }

interface IImageSets {
  digitalAssetType: string;
  basePhotoUrl: string;
  largePhotoUrl: string;
  mediumPhotoUrl: string;
  thumbnailPhotoUrl: string;
  scanDate: string;
}

interface IPropertyImageResponse {
  defaultImage: IImageSets;
  secondaryImageList: IImageSets[];
  floorPlanImageList: IImageSets[];
  isActiveProperty: boolean;
}
const PropertyDetailCard: FC<PropertyDetailCardProps> = ({ item, view, handleRemoveWatchlist }) => {
  const nav = useNavigate();

  // const [details, setDetails] = useState<IPropertyDetail | null>(null);
  const [image, setImage] = useState<string>('');

  const handleDetails = (targetID: number): void => {
    // requestForThirdParty<IPropertyDetail>(
    //   'get',
    //   `property-details/au/properties/${targetID}/attributes/core`,
    // )
    //   .then((result) => {
    //     setDetails(result.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    request<IPropertyImageResponse>('get', `/corelogic/images/${targetID}`)
      .then((res) => {
        setImage(res.data.defaultImage.basePhotoUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (window.location.pathname.includes('watchlist')) {
  //     setInWatchList(true);
  //   } else {
  //     setInWatchList(false);
  //   }
  // }, []);

  // const handleTolist = (item: ItemData, details: IPropertyDetail, image: string): void => {
  //   const obj = {
  //     productId: item.propertyId,
  //     propertyTitle: item?.suggestion,
  //     lastListed: 122324,
  //     image,
  //     propertyDetail: {
  //       beds: details.beds,
  //       baths: details.baths,
  //       parking: !!details.carSpaces,
  //       houseArea: {
  //         home: 1212,
  //         area: details.isCalculatedLandArea as unknown as number,
  //       },
  //       saleListed: '2023-01-01',
  //       lastSold: '2022-06-15',
  //       propertyStatus: 'For Sale',
  //       ownerDetails: 'available',
  //     },
  //   };
  //   handleAddToWatchList?.(obj);
  // };

  useEffect(() => {
    if (item.productId) {
      handleDetails(item.productId);
    }
  }, [item.productId]);

  return (
    <>
      <Box
        minWidth={'100%'}
        height={'200px'}
        display={view === 'listView' ? 'flex' : 'none'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={'15px'}
        padding={'10px'}
        borderRadius={'12px'}
        sx={{
          // cursor: 'pointer',

          '@media (max-width: 500px)': {
            flexDirection: 'column',
            height: 'unset',
            alignItems: 'flex-start',
          },

          '&:hover': {
            background: 'rgba(255, 255, 255, 0.05)',
          },
        }}
      >
        <Box
          width={'250px'}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
              height: '150px',
            },
          }}
        >
          <img
            src={item.image ?? placeholderImage}
            alt=""
            style={{ width: '100%', borderRadius: '8px', height: '150px' }}
          />
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          height={'85%'}
          width={'64%'}
          gap={'12px'}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
            },
          }}
        >
          <Typography
            variant="h6"
            color={'white'}
            fontWeight={'600'}
            sx={{ cursor: 'pointer' }}
            textTransform={'capitalize'}
            onClick={() => {
              nav(`/details/${item?.propertyTitle}/${item.productId}`);
            }}
          >
            {item?.propertyTitle}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'2%'}
            width={'100%'}
          >
            <Typography
              textTransform={'capitalize'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                },
              }}
              color={'white'}
              fontWeight={'500'}
            >
              Last Listed
            </Typography>
            <Typography
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                },
              }}
              textTransform={'capitalize'}
              color={'white'}
              fontWeight={'700'}
              lineHeight={'22.4px'}
            >
              $ {item.lastListed ?? '--'}
            </Typography>
          </Box>

          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'15px'}
            flexWrap={'wrap'}
            width={'100%'}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'10px'}>
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group26 width={'100%'} />
              </Box>
              <Typography
                color={'white'}
                display={'block'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.beds
                  ? item?.propertyDetail?.beds < 2
                    ? `${item?.propertyDetail?.beds} Bed`
                    : `${item?.propertyDetail?.beds} Beds`
                  : '-'}
              </Typography>
              <Typography
                color={'white'}
                display="none"
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.beds ? item?.propertyDetail?.beds : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              width={'6rem'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                  width: 'unset',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group27 width={'100%'} />
              </Box>
              <Typography
                color={'white'}
                display={'block'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.baths
                  ? item?.propertyDetail?.baths < 2
                    ? `${item?.propertyDetail?.baths} Bath`
                    : `${item?.propertyDetail?.baths} Baths`
                  : '- Bath'}
              </Typography>
              <Typography
                color={'white'}
                display={'none'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.baths ? item?.propertyDetail?.baths : '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group28 width={'100%'} />
              </Box>
              <Typography
                display="block"
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                width={'8rem'}
              >
                {item?.propertyDetail?.parking ? item?.propertyDetail?.parking : '--'} Parking Area
              </Typography>
              <Typography
                display="none"
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.parking ? item?.propertyDetail?.parking : '--'}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              width={'8rem'}
              sx={{
                '@media (max-width: 500px)': {
                  width: 'unset',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group29 width={'100%'} />
              </Box>
              <Typography
                display={'block'}
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'none',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.houseArea?.area ?? '-'}M Area
              </Typography>
              <Typography
                color={'white'}
                display={'none'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    display: 'block',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.houseArea?.area ?? '-'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              width={'6rem'}
              sx={{
                '@media (max-width: 500px)': {
                  width: 'unset',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '20px',
                  },
                }}
              >
                <Group30 width={'100%'} />
              </Box>
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {/* {item.propertyAreaArea} */} --
              </Typography>
            </Box>
          </Box>

          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'20px'}
            width={'100%'}
            sx={{
              '@media (max-width: 500px)': {
                flexWrap: 'wrap',
                gap: '6px',
              },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                textTransform={'capitalize'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Listed For Sale
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                textTransform={'capitalize'}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {item.propertyDetail?.saleListed ?? '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                textTransform={'capitalize'}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Last Sold
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                textTransform={'capitalize'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {moment(item?.propertyDetail.lastSold).format('D MMM YYYY') ?? '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                textTransform={'capitalize'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Property Status
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    whiteSpace: 'pre',
                  },
                }}
                textTransform={'capitalize'}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {item.propertyDetail?.propertyStatus ?? '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                textTransform={'capitalize'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Owner&apos;s Details
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                textTransform={'capitalize'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail?.ownerDetails ?? '--'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            '@media (max-width: 500px)': {
              display: 'none',
            },
          }}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          height={'90%'}
        >
          <Button
            onClick={() => {
              if (item?._id) {
                handleRemoveWatchlist(item?._id);
              }
            }}
            endIcon={<HeartIcon />}
            sx={{
              background: 'rgba(219, 60, 54, 0.15)',
              border: '1px solid rgba(219, 60, 54, 0.5)',
              textTransform: 'capitalize',
              borderRadius: '12px',
              color: '#DB3C36',
              fontWeight: '500',
              fontSize: '16px',
              padding: '12px 24px',
              whiteSpace: 'nowrap',
              '&:hover': {
                background: 'rgba(219, 60, 54, 0.15)',
              },
            }}
          >
            Remove from Watchlist
          </Button>
        </Box>
      </Box>
      <Box
        margin={'.7%'}
        width={'31%'}
        height={'450px'}
        display={view === 'gridView' ? 'flex' : 'none'}
        // alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        gap={'15px'}
        padding={'1.2%'}
        borderRadius={'12px'}
        sx={{
          // cursor: 'pointer',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <img
            src={image ?? placeholderImage}
            alt=""
            style={{
              height: '150px',
              width: '413px',
              objectFit: 'cover',
            }}
          />
          <HeartIcon
            onClick={() => {
              if (item?._id) {
                handleRemoveWatchlist(item?._id);
              }
            }}
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
            }}
          />
          <Typography
            textTransform={'capitalize'}
            color={'white'}
            padding={'10px'}
            sx={{
              background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
              position: 'absolute',
              top: 10,
              left: 20,
            }}
          >
            1 Note Added
          </Typography>
        </Box>

        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          height={'85%'}
          width={'100%'}
        >
          <Typography
            textTransform={'capitalize'}
            variant="h6"
            color={'white'}
            fontWeight={'600'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              nav('/details');
            }}
          >
            {item?.propertyTitle}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'2%'}
            width={'100%'}
          >
            <Typography color={'white'} fontWeight={'500'} textTransform={'capitalize'}>
              Last Listed
            </Typography>
            <Typography
              color={'white'}
              fontWeight={'700'}
              lineHeight={'22.4px'}
              textTransform={'capitalize'}
            >
              {/* {item.lastListed} */} --
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            // justifyContent={'flex-start'}
            gap={'5%'}
            flexWrap={'wrap'}
            // height={'10%'}
            // bgcolor={'red'}
            // paddingTop={'10%'}
            // paddingBottom={'10%'}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              padding={'8px 0px'}
            >
              <Group26 />
              <Typography
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {item?.propertyDetail
                  ? item?.propertyDetail?.beds < 2
                    ? `${item?.propertyDetail?.beds} Bed`
                    : `${item?.propertyDetail?.beds} Beds`
                  : '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              padding={'8px 0px'}
            >
              <Group27 />
              <Typography
                textTransform={'capitalize'}
                width={'4rem'}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {item?.propertyDetail
                  ? item?.propertyDetail?.baths < 2
                    ? `${item?.propertyDetail?.baths} Bath`
                    : `${item?.propertyDetail?.baths} Baths`
                  : '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              padding={'8px 0px'}
              gap={'10%'}
            >
              <Group28 />
              <Typography
                color={'white'}
                textTransform={'capitalize'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                width={'6rem'}
              >
                {item?.propertyDetail?.parking ?? '--'} Parking Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              padding={'8px 0px'}
              width={'6rem'}
            >
              <Group29 />
              <Typography
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {item?.propertyDetail?.houseArea?.area ?? '--'}M Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              padding={'8px 0px'}
              width={'6rem'}
            >
              <Group30 />
              <Typography
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {/* {item.propertyAreaArea} */} --
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            flexWrap={'wrap'}
            // justifyContent={'center'}
            // flexDirection={'row'}
            // gap={'20px'}
            // bgcolor={'green'}
            // width={'100%'}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              // bgcolor={'red'}
            >
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                Listed For Sale
              </Typography>
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'300'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {item.propertyDetail?.saleListed ?? '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                Last Sold
              </Typography>
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'300'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {moment(item.propertyDetail.lastSold).format('D MMM YYYY') ?? '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                Property Status
              </Typography>
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'300'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {item.propertyDetail?.propertyStatus ?? '--'}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Owner&apos;s Details
              </Typography>
              <Typography
                color={'white'}
                fontSize={'14px'}
                fontWeight={'300'}
                lineHeight={'22px'}
                textTransform={'capitalize'}
              >
                {item?.propertyDetail?.ownerDetails ?? '--'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PropertyDetailCard;
