import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import User, { IUserResponse } from 'types/user';
import request from 'api/request';
import { mediaUrl } from 'config';
import toast from 'react-hot-toast';

interface IImagePicker {
  height: string;
  user?: User;
  setOpenToast: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
}

const ImagePicker: FC<IImagePicker> = ({ height, user, setOpenToast, setMessage }) => {
  const [image, setImage] = useState<File | string>();

  const handleImage = (e: any): void => {
    const formdata = new FormData();
    if (e.target.files?.length && user) {
      setImage(e.target.files[0]);
      formdata.append('image', e.target.files[0]);
      request('put', `/users/${user._id}`, formdata)
        .then((res) => {
          console.log(res);
          localStorage.setItem('user', JSON.stringify((res.data as IUserResponse)?.user));
          window.dispatchEvent(new Event('storage'));
          setMessage?.('Uploaded Successfully!');
          setOpenToast?.(true);
        })
        .catch((err) => {
          console.log('🚀 ~ handleImage ~ err:', err);
          toast.error(<b>{err.response.data.message}</b>);
        });
    }
  };
  useEffect(() => {
    if (user) setImage(user?.profile_pic);
  }, [user]);

  return (
    <label
      htmlFor="addProfile_Pic"
      style={{
        borderRadius: '100%',
        cursor: 'pointer',
      }}
    >
      <input
        name="profile_pic"
        type="file"
        id="addProfile_Pic"
        accept=".png, .jpg, .jpeg"
        onChange={handleImage}
        style={{
          display: 'none',
        }}
      />

      {image ? (
        <Box
          height={height}
          width={height}
          sx={{
            '@media (max-width: 500px)': {
              height: window.location.pathname.includes('profile') ? '120px' : '80px',
              width: window.location.pathname.includes('profile') ? '120px' : '80px',
            },
          }}
        >
          <img
            style={{
              border: '5px solid gray',
              borderRadius: '100%',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            src={typeof image === 'string' ? `${mediaUrl}/${image}` : URL.createObjectURL(image)}
            alt=""
          />
        </Box>
      ) : (
        <Box
          height={height}
          sx={{
            '@media (max-width: 500px)': {
              height: window.location.pathname.includes('profile') ? '120px' : '80px',
              width: window.location.pathname.includes('profile') ? '120px' : '80px',
            },
          }}
          width={height}
          border={'5px solid gray'}
          borderRadius={'100%'}
          bgcolor={'white'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          fontSize={'25px'}
          fontWeight={'bold'}
          textTransform="uppercase"
        >
          {user?.firstName ? user?.firstName.substring(0, 1) : ''}
          {user?.lastName ? user?.lastName.substring(0, 1) : ''}
          {/* <PencilIcon /> */}
        </Box>
      )}
    </label>
  );
};

export default ImagePicker;
