import React, { FC, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { style, variants } from './Login';
import { useNavigate } from 'react-router-dom';
import Header from 'components/common/Header';
import { motion, AnimatePresence } from 'framer-motion';
import request from 'api/request';

const ForgotPassword: FC = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState('');

  const handleForget = (): void => {
    if (email) {
      request('put', `/auth/forgot/${email}`)
        .then((res) => {
          console.log(res);
          nav('/resetpassword');
        })
        .catch((err) => {
          console.log('🚀 ~ handleForget ~ err:', err);
        });
    }
  };
  return (
    <>
      <Header logo back />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <AnimatePresence>
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={variants}
            className="login_container"
            style={{
              gap: '52px',
            }}
          >
            <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '24px',
                    marginTop: '40px',
                  },
                }}
                fontWeight="bold"
                fontSize="32px"
                color="white"
              >
                Forgot Password
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography
                  sx={{
                    '@media (max-width: 500px)': {
                      width: '90%',
                    },
                  }}
                  width="65%"
                  fontWeight="regular"
                  color="white"
                >
                  Enter your email so we can send you a verification code
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Typography fontSize="16px" color="white">
                Email
              </Typography>
              <TextField
                sx={style}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    color: 'white',
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter your email address"
                fullWidth
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Box>
            <Button
              onClick={handleForget}
              fullWidth
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                borderRadius: '12px',
                backgroundColor: 'white',
                textTransform: 'unset',
                color: 'black',
                padding: '12px 24px',
              }}
            >
              Send
            </Button>
          </motion.div>
        </AnimatePresence>
      </Box>
    </>
  );
};

export default ForgotPassword;
