/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
// import PropertyImg from 'assets/propertyDetailCard/BG.png';
// import bgGrid from 'assets/bgGrid.png';
import { ReactComponent as Group26 } from 'assets/propertyDetailCard/Group26.svg';
import { ReactComponent as Group27 } from 'assets/propertyDetailCard/Group27.svg';
import { ReactComponent as Group28 } from 'assets/propertyDetailCard/Group31.svg';
import { ReactComponent as Group29 } from 'assets/propertyDetailCard/Group34.svg';
import { ReactComponent as Group30 } from 'assets/propertyDetailCard/Group38.svg';
import { ReactComponent as HeartIcon } from 'assets/heartIcon.svg';
import { ReactComponent as WhiteHeartIcon } from 'assets/whiteHeart1.svg';
import { useNavigate } from 'react-router-dom';
import placeholderImage from 'assets/placeholder-image.png';
import request from 'api/request';
import { IProperty, ILastSaleResponse, IWatchlist, INote } from 'types/user';
import moment from 'moment';

// import { IProperty } from 'types/user';
// import moment from 'moment';

interface PropertyDetailCardProps {
  item: ItemData;
  view: string;
  handleAddToWatchList?: (obj: IProperty) => void;
  handleRemoveWatchlist: (id: string) => void;
  watchlist: IWatchlist[];
  notes?: INote;
}

interface ItemData {
  councilAreaId: number;
  countryId: number;
  isActiveProperty: boolean;
  isBodyCorporate: boolean;
  isUnit: boolean;
  localityId: number;
  postcodeId: number;
  propertyId: number;
  stateId: number;
  streetId: number;
  suggestion: string;
  suggestionType: string;
}

interface IPropertyDetail {
  isActiveProperty: boolean;
  propertyType: string;
  propertySubType: string;
  propertySubTypeShort: string;
  beds: number;
  baths: number;
  carSpaces: number;
  lockUpGarages: number;
  landArea: number;
  isCalculatedLandArea: boolean;
  landAreaSource: string;
  propertyId: string;
}

interface IImageSets {
  digitalAssetType: string;
  basePhotoUrl: string;
  largePhotoUrl: string;
  mediumPhotoUrl: string;
  thumbnailPhotoUrl: string;
  scanDate: string;
}

interface IPropertyImageResponse {
  defaultImage: IImageSets;
  secondaryImageList: IImageSets[];
  floorPlanImageList: IImageSets[];
  isActiveProperty: boolean;
}
const PropertyDetailCard: FC<PropertyDetailCardProps> = ({
  item,
  view,
  handleAddToWatchList,
  handleRemoveWatchlist,
  watchlist,
  notes,
}) => {
  const nav = useNavigate();
  // const [btn, setBtn] = useState<boolean>(false);
  // const [inWatchList, setInWatchList] = useState<boolean>(false);
  const [details, setDetails] = useState<IPropertyDetail | null>(null);
  const [image, setImage] = useState<string>('');
  const [lastSale, setLastSale] = useState<ILastSaleResponse>();

  const handleDetails = (targetID: number): void => {
    request<IPropertyDetail>('get', `/corelogic/details/${targetID}`)
      .then((result) => {
        setDetails(result.data);
      })
      .catch((err) => {
        console.log(err);
      });

    request<IPropertyImageResponse>('get', `/corelogic/images/${targetID}`)
      .then((res) => {
        setImage(res.data.defaultImage.basePhotoUrl);
      })
      .catch((err) => {
        console.log(err);
      });

    request<ILastSaleResponse>('get', `/corelogic/last-sale/${targetID}`)
      .then((res) => {
        console.log(res);
        setLastSale(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const checker = (propertyId: number): boolean => {
    return watchlist?.some((item) => item.productId === propertyId);
  };
  // useEffect(() => {
  //   if (window.location.pathname.includes('watchlist')) {
  //     setBtn(true);
  //     setInWatchList(true);
  //   } else {
  //     setBtn(false);
  //     setInWatchList(false);
  //   }
  // }, []);

  const handleTolist = (item: ItemData, details: IPropertyDetail, image: string): void => {
    const obj = {
      productId: item.propertyId,
      propertyTitle: item?.suggestion,
      lastListed: lastSale?.lastSale?.price.toLocaleString() ?? '--',
      image,
      propertyDetail: {
        beds: details.beds,
        baths: details.baths,
        parking: !!details.carSpaces,
        houseArea: {
          home: 0,
          area: details.isCalculatedLandArea as unknown as number,
        },
        saleListed: '2023-01-01',
        lastSold: '1111-11-11',
        propertyStatus: '--',
        ownerDetails: 'unavailable',
      },
    };
    handleAddToWatchList?.(obj);
  };

  useEffect(() => {
    if (item.propertyId) {
      handleDetails(item.propertyId);
    }
  }, [item.propertyId]);
  return (
    <>
      <Box
        minWidth={'100%'}
        height={'200px'}
        display={view === 'listView' ? 'flex' : 'none'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={'15px'}
        padding={'10px'}
        borderRadius={'12px'}
        sx={{
          // cursor: 'pointer',

          '@media (max-width: 500px)': {
            flexDirection: 'column',
            height: 'unset',
            alignItems: 'flex-start',
          },

          '&:hover': {
            background: 'rgba(255, 255, 255, 0.05)',
          },
        }}
      >
        <Box
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
              height: '150px',
            },
          }}
        >
          <img src={image ?? placeholderImage} alt="" style={{ width: '250px', height: '150px' }} />
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          height={'85%'}
          width={'64%'}
          gap={'12px'}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
            },
          }}
        >
          <Typography
            variant="h6"
            color={'white'}
            fontWeight={'600'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              nav(`/details/${item?.suggestion}/${item.propertyId}`);
            }}
          >
            {item?.suggestion}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'2%'}
            width={'100%'}
          >
            <Typography
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                },
              }}
              color={'white'}
              fontWeight={'500'}
            >
              Last Listed
            </Typography>
            <Typography
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '12px',
                },
              }}
              color={'white'}
              fontWeight={'700'}
              lineHeight={'22.4px'}
            >
              ${lastSale?.lastSale?.price?.toLocaleString()}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'15px'}
            flexWrap={'wrap'}
            width={'100%'}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'10px'}>
              <Group26 />
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {details && details?.beds < 2 ? `${details?.beds} Bed` : `${details?.beds} Beds`}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              width={'6rem'}
            >
              <Group27 />
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {details && details?.baths < 2
                  ? `${details?.baths} Bath`
                  : `${details?.baths} Baths`}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
            >
              <Group28 />
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                width={'8rem'}
              >
                {details?.carSpaces} Parking Area
              </Typography>
            </Box>

            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              width={'6rem'}
            >
              <Group29 />
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {details?.landArea}M Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              width={'6rem'}
            >
              <Group30 />
              <Typography
                color={'white'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {/* {item.propertyAreaArea} */} --
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'20px'}
            width={'100%'}
            sx={{
              '@media (max-width: 500px)': {
                flexWrap: 'wrap',
                gap: '6px',
              },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Listed For Sale
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {/* {item.listedForSale} */} --
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Last Sold
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {moment(lastSale?.lastSale?.contractDate).format('D MMM YYYY')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Property Status
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                    whiteSpace: 'pre',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {/* {item.propertyStatus} */} --
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                Owner&apos;s Details
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '12px',
                  },
                }}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'300'}
                lineHeight={'22px'}
              >
                {/* {item.ownerDetails} */} --
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            '@media (max-width: 500px)': {
              display: 'none',
            },
          }}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          height={'90%'}
        >
          <Button
            onClick={() => {
              if (checker(item.propertyId)) {
                const matchedItem = watchlist.find(
                  (watchlistItem) => watchlistItem.productId === item.propertyId,
                );
                if (matchedItem) {
                  handleRemoveWatchlist(matchedItem._id);
                }
              } else {
                if (item && details && image) {
                  handleTolist(item, details, image);
                }
              }
            }}
            endIcon={checker(item.propertyId) ? <HeartIcon /> : <WhiteHeartIcon />}
            sx={{
              background: checker(item.propertyId) ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              border: checker(item.propertyId)
                ? '1px solid rgba(219, 60, 54, 0.5)'
                : '1px solid rgba(255, 255, 255, 0.1)',
              textTransform: 'capitalize',
              borderRadius: '12px',
              color: checker(item.propertyId) ? '#DB3C36' : 'white',
              fontWeight: '500',
              fontSize: '16px',
              padding: '12px 24px',
              whiteSpace: 'nowrap',
              '&:hover': {
                background: checker(item.propertyId) ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              },
            }}
          >
            {checker(item.propertyId) ? 'Remove from Watchlist' : 'Add To Watchlist'}
          </Button>
        </Box>
      </Box>
      <Box
        margin={'.7%'}
        width={'31%'}
        height={'450px'}
        display={view === 'gridView' ? 'flex' : 'none'}
        // alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        gap={'15px'}
        padding={'1.2%'}
        borderRadius={'12px'}
        sx={{
          // cursor: 'pointer',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <img
            src={image ?? placeholderImage}
            alt=""
            style={{
              height: '155px',
              width: '450px',
              objectFit: 'cover',
            }}
          />
          {checker(item.propertyId) ? (
            <HeartIcon
              style={{
                position: 'absolute',
                top: '6%',
                right: '3%',
                cursor: 'pointer',
              }}
            />
          ) : (
            <WhiteHeartIcon
              style={{
                position: 'absolute',
                top: '6%',
                right: '3%',
                cursor: 'pointer',
              }}
            />
          )}
          <Typography
            textTransform={'capitalize'}
            color={'white'}
            padding={'10px'}
            sx={{
              display: checker(item.propertyId) ? '' : 'none',
              background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
              position: 'absolute',
              top: 10,
              left: 20,
            }}
          >
            1 Note Added
          </Typography>
        </Box>

        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          height={'85%'}
          width={'100%'}
        >
          <Typography
            variant="h6"
            color={'white'}
            fontWeight={'600'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              nav('/details');
            }}
          >
            {item?.suggestion}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            gap={'2%'}
            width={'100%'}
          >
            <Typography color={'white'} fontWeight={'500'}>
              Last Listed
            </Typography>
            <Typography color={'white'} fontWeight={'700'} lineHeight={'22.4px'}>
              ${lastSale?.lastSale?.price?.toLocaleString()}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            // justifyContent={'flex-start'}
            gap={'5%'}
            flexWrap={'wrap'}
            // height={'10%'}
            // bgcolor={'red'}
            // paddingTop={'10%'}
            // paddingBottom={'10%'}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              padding={'8px 0px'}
            >
              <Group26 />
              <Typography color={'white'} fontSize={'13px'} fontWeight={'600'} lineHeight={'22px'}>
                {details && details?.beds < 2 ? `${details?.beds} Bed` : `${details?.beds} Beds`}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              padding={'8px 0px'}
            >
              <Group27 />
              <Typography
                width={'4rem'}
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
              >
                {details && details?.baths < 2
                  ? `${details?.baths} Bath`
                  : `${details?.baths} Baths`}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              padding={'8px 0px'}
              gap={'10%'}
            >
              <Group28 />
              <Typography
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                width={'8rem'}
              >
                {details?.carSpaces} Parking Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              padding={'8px 0px'}
              width={'8rem'}
            >
              <Group29 />
              <Typography color={'white'} fontSize={'13px'} fontWeight={'600'} lineHeight={'22px'}>
                {details?.landArea}M Area
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10%'}
              padding={'8px 0px'}
              width={'6rem'}
            >
              <Group30 />
              <Typography color={'white'} fontSize={'13px'} fontWeight={'600'} lineHeight={'22px'}>
                {/* {item.propertyAreaArea} */} --
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            flexWrap={'wrap'}
            // justifyContent={'center'}
            // flexDirection={'row'}
            // gap={'20px'}
            // bgcolor={'green'}
            // width={'100%'}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
              // bgcolor={'red'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Listed For Sale
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {/* {item.listedForSale} */} --
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Last Sold
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {moment(lastSale?.lastSale?.contractDate).format('D MMM YYYY')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Property Status
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {/* {item.propertyStatus} */} --
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              gap={'10px'}
            >
              <Typography color={'white'} fontSize={'14px'} fontWeight={'600'} lineHeight={'22px'}>
                Owner&apos;s Details
              </Typography>
              <Typography color={'white'} fontSize={'14px'} fontWeight={'300'} lineHeight={'22px'}>
                {/* {item.ownerDetails} */} --
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Box display={'flex'} alignItems={'flex-start'} justifyContent={'center'} height={'90%'}>
          <Button
            onClick={() => {
              setBtn(!btn);
            }}
            endIcon={btn ? <HeartIcon /> : <WhiteHeartIcon />}
            sx={{
              background: btn ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              border: btn
                ? '1px solid rgba(219, 60, 54, 0.5)'
                : '1px solid rgba(255, 255, 255, 0.1)',
              textTransform: 'capitalize',
              borderRadius: '12px',
              color: btn ? '#DB3C36' : 'white',
              fontWeight: '500',
              fontSize: '16px',
              padding: '12px 24px',
              whiteSpace: 'nowrap',
              '&:hover': {
                background: btn ? 'rgba(219, 60, 54, 0.15)' : 'transparent',
              },
            }}
          >
            {btn ? 'Remove from Watchlist' : 'Add To Watchlist'}
          </Button>
        </Box> */}
      </Box>
    </>
  );
};

export default PropertyDetailCard;
