import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as ListViewIcon } from 'assets/listViewIcon.svg';
import { ReactComponent as ListGridIcon } from 'assets/listGridIcon.svg';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as InactivelistGridIcon } from 'assets/inactivelistGridIcon.svg';
import { ReactComponent as InactiveListViewIcon } from 'assets/inactiveListViewIcon.svg';
// import Searchbar from './Searchbar';
import { ReactComponent as Drop } from '../../assets/arrowDropdown.svg';
import AutocompleteSearch from './AutocompleteSearch';
interface SortingbarProp {
  text: string;
  option?: string;
  view: string;
  totalItems?: number;
  handleTargetListView: (value: string) => void;
  handleFilter?: (value: string) => void;
}

const Sortingbar: FC<SortingbarProp> = ({
  text,
  view,
  handleTargetListView,
  totalItems,
  handleFilter,
  option,
}) => {
  return (
    <Box
      width={'82vw'}
      mt={'9%'}
      sx={{
        '@media (max-width: 500px)': {
          marginTop: '100px',
        },
      }}
    >
      <Box display={window.location.pathname.includes('suburb') ? 'block' : 'none'} mb={'20px'}>
        <AutocompleteSearch />
      </Box>

      <Box
        sx={{
          '@media (max-width: 500px)': {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            gap: '12px',
          },
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {window.location.pathname.includes('suburb') && (
          <Typography
            display="none"
            sx={{
              '@media (max-width: 500px)': {
                display: 'block',
              },
            }}
            fontSize={'10px'}
            color={'#FFFFFF'}
            fontWeight={'600'}
          >
            {totalItems} RESULTS
          </Typography>
        )}
        <Typography
          sx={{
            '@media (max-width: 500px)': {
              fontSize: '14px',
            },
          }}
          fontSize={'18px'}
          color={'rgb(153, 153, 153)'}
          fontWeight={'600'}
          fontFamily={'Inter'}
        >
          {text}
        </Typography>
        <Box
          display={'flex'}
          alignItems="center"
          gap={'22px'}
          sx={{
            '@media (max-width: 500px)': {
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexDirection: 'column-reverse',
            },
          }}
        >
          {window.location.pathname.includes('suburb') && (
            <>
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    display: 'none',
                  },
                }}
                fontSize={'10px'}
                color={'#FFFFFF'}
                fontWeight={'600'}
              >
                {totalItems} RESULTS
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={option}
                onChange={(e) => {
                  handleFilter?.(e.target.value);
                }}
                IconComponent={Drop}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: 'black',
                      color: 'white',
                      '& .MuiMenuItem-root': {
                        '&.Mui-selected': {
                          '&:hover': {
                            backgroundColor: 'grey',
                          },
                          backgroundColor: 'gray',
                        },
                        '&:hover': {
                          backgroundColor: 'darkgray',
                        },
                      },
                    },
                  },
                }}
                sx={{
                  minWidth: 100,
                  height: 40,
                  border: '1px solid white',
                  color: 'white',
                  borderRadius: '12px',
                  '& .MuiSelect-icon': {
                    top: 'unset',
                  },
                }}
              >
                <MenuItem value={'1'}>Default Sorting</MenuItem>
                <MenuItem value={'2'}>Address (A-Z)</MenuItem>
                <MenuItem value={'3'}>Address (Z-A)</MenuItem>
                <MenuItem value={'4'}>Date (Oldest to Newest)</MenuItem>
                <MenuItem value={'5'}>Date (Newest to Oldest)</MenuItem>
                {/* <MenuItem value={'3'}>Lowest-Highest</MenuItem> */}
              </Select>
            </>
          )}
          <Box
            border={'1px solid #FFFFFF'}
            borderRadius={'12px'}
            display={'flex'}
            // width={'9%'}
            sx={{
              '@media (max-width: 500px)': {
                display: 'none',
              },
            }}
          >
            <Box
              onClick={() => {
                handleTargetListView('gridView');
              }}
              // width={'50%'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={'10px'}
              bgcolor={view === 'gridView' ? '#1e1e1e' : 'transparent'}
              sx={{
                cursor: 'pointer',
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
              }}
            >
              {view === 'gridView' ? <ListGridIcon /> : <InactivelistGridIcon />}
            </Box>
            <Box
              onClick={() => {
                handleTargetListView('listView');
              }}
              // width={'50%'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={'10px'}
              bgcolor={view === 'listView' ? '#1e1e1e' : 'transparent'}
              sx={{
                cursor: 'pointer',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
              }}
            >
              {view === 'listView' ? <ListViewIcon /> : <InactiveListViewIcon />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sortingbar;
