import React, { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
// Auth components
import Home from 'pages/Home.page';
import Profile from 'pages/Profile.page';
import ErrorScreen from 'pages/errorScreen.page';
import Search from 'pages/Search.page';
import SearchedProperty from 'pages/searchedProperties.page';
import SearchedSuburbs from 'pages/searchedSuburbs.page';
import SearchedSubrubProperties from 'pages/searchedSubrubProperties.page';
import Watchlist from 'pages/watchlist.page';
// unAuth components
import Login from 'unAuth/Login';
import ResetPassword from 'unAuth/resetPassword.unAuth';
import ForgetPassword from 'unAuth/forgetPassword.unAuth';
import PaymentScreen from 'unAuth/payment.unAuth';
import PaymentSuccess from 'unAuth/paymentSuccess.unAuth';
import Register from 'unAuth/register.unAuth';
import DetailsPage from 'pages/Details.page';
import PageNotFound from 'pages/pageNotFound.page';
// import StripeScreen from 'unAuth/stripeScreen';
// import LandingPage from 'unAuth/landingPage';
import VerifyEmail from 'components/popups/verifyEmail';
import request from 'api/request';
import { IUserResponse } from 'types/user';
import LandingPageNew from 'unAuth/LandingPageNew';
import TermsAndConditions from 'pages/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';

// params search
const queryParams = new URLSearchParams(window.location.search);
// for google
const SignupByGoogle = queryParams.get('SignupByGoogle');
const GoogleSignupToken = queryParams.get('googleSignupToken');
const LoginByGoogle = queryParams.get('LoginByGoogle');
const LoginByGoogleToken = queryParams.get('LoginByGoogleToken');
// for facebook
const SignupByFacebook = queryParams.get('SignupByFacebook');
const FacebookSignupToken = queryParams.get('FacebookSignupToken');
const LoginByFacbook = queryParams.get('LoginByFacebook');
const LoginByFacebookToken = queryParams.get('LoginByFacebookToken');
// for email verification
const verifyEmail = queryParams.get('verifyEmail');
const verifyEmailToken = queryParams.get('verifyEmailToken');
// for payment success
const userId = queryParams.get('userId');
const membershipType = queryParams.get('membership_type');
const membershipPlan = queryParams.get('membership_plan');
const sessionID = queryParams.get('sessionID');

interface RoutesProps {
  loggedIn: boolean;
}
const index: FC<RoutesProps> = ({ loggedIn }) => {
  const nav = useNavigate();

  useEffect(() => {
    if (SignupByGoogle && GoogleSignupToken) {
      nav(`/pricingPlan/${GoogleSignupToken}`);
    } else if (LoginByGoogle && LoginByGoogleToken) {
      request<IUserResponse>('post', '/auth/verify-user', {
        LoginByGoogleToken,
      })
        .then((res) => {
          console.log(res);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          window.location.href = '/home';
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (SignupByFacebook && FacebookSignupToken) {
      nav(`/pricingPlan/${FacebookSignupToken}`);
    } else if (LoginByFacbook && LoginByFacebookToken) {
      request<IUserResponse>('post', '/auth/verify-user', {
        LoginByFacebookToken,
      })
        .then((res) => {
          console.log(res);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          window.location.href = '/home';
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (verifyEmail && verifyEmailToken) {
      nav(`/verification/${verifyEmailToken}`);
    } else if (sessionID && membershipType && membershipPlan && userId) {
      nav(`/paymentSuccess/success/${userId}/${membershipType}/${membershipPlan}/${sessionID}`);
    }
  }, []);
  return (
    <Routes>
      {loggedIn ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/search" element={<Search />} />
          {/* <Route path="/results" element={<SearchedProperty />} /> */}
          <Route path="/results/:searchedProperty" element={<SearchedProperty />} />
          <Route path="/searchedSuburbResult/:searchedSuburb" element={<SearchedSuburbs />} />
          <Route path="/details/:title/:id" element={<DetailsPage />} />
          <Route path="/suburb-details/:title/:id/:type" element={<SearchedSubrubProperties />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/watchlist" element={<Watchlist />} />
          {/* <Route path="/payment" element={<StripeScreen />} /> */}
          <Route path="/pricingPlan/:token" element={<PaymentScreen />} />
        </>
      ) : (
        <>
          <Route path="/" element={<LandingPageNew />} />
          <Route path="/landing" element={<LandingPageNew />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verification/:token" element={<VerifyEmail />} />
          <Route path="/pricingPlan/:token" element={<PaymentScreen />} />
          <Route
            path="/paymentSuccess/success/:userId/:membership_type/:membership_plan/:sessionID"
            element={<PaymentSuccess />}
          />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/error" element={<ErrorScreen />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </>
      )}
    </Routes>
  );
};

export default index;
