/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import Header from 'components/common/Header';
import Drawer from 'components/common/Drawer';
import Sortingbar from 'components/common/sortingbar.component';
import PropertyDetailCard from 'components/common/propertyDetailCard.component';
import { motion } from 'framer-motion';
import { variants } from 'unAuth/Login';
import {
  INote,
  IPostWatchlistResponse,
  IProperty,
  IWatchlist,
  // IGetNotesAndWatchlist,
} from 'types/user';
import { useParams, useNavigate } from 'react-router-dom';
import request from 'api/request';
import { ReactComponent as NoWatchlist } from 'assets/noSearch.svg';
import { UpdateStore } from 'context/store';
import { getAllWatchList } from 'hooks/getWatchList';
import RequestSubmitted from 'components/popups/requestSubmitted';
// import success from 'assets/success.gif';

interface ISearchOption {
  councilAreaId: number;
  countryId: number;
  isActiveProperty: boolean;
  isBodyCorporate: boolean;
  isUnit: boolean;
  localityId: number;
  postcodeId: number;
  propertyId: number;
  stateId: number;
  streetId: number;
  suggestion: string;
  suggestionType: string;
}

interface IPropertySearchResponse {
  suggestions: ISearchOption[];
}

interface IWatchlistandNotes {
  success: boolean;
  watch: IWatchlist[];
  notes: INote;
}

const Home: FC = () => {
  const param = useParams();
  const updateStore = UpdateStore();

  const { searchedSuburb } = param;
  const userJson = localStorage.getItem('user');
  const user = userJson ? JSON.parse(userJson) : '';
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<string>('listView');
  const [propertyList, setPropertyList] = useState<ISearchOption[]>([]);
  const [filteredList, setFilteredList] = useState<ISearchOption[]>([]);

  const [notes, setNotes] = useState<INote | undefined>(undefined);
  const [watchlist, setWatchlist] = useState<IWatchlist[]>([]);
  const [option, setOption] = React.useState('1');
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const handleCounter = useCallback(() => {
    if (user) {
      request<IWatchlistandNotes>('get', `/watchlist/notes/${user._id}`)
        .then((res) => {
          setNotes(res.data.notes);
          setWatchlist(res.data.watch);
        })
        .catch(console.log);
    }
  }, [user]);

  const handleSearch = (targetValue: string): void => {
    setLoading(true);
    request<IPropertySearchResponse>(
      'get',
      `/corelogics/property-search?q=${targetValue}&searchBy=locality`,
    )
      .then((res) => {
        console.log('res', res);
        setPropertyList(res.data.suggestions);
        setFilteredList(res.data.suggestions);
      })
      .catch((err: any) => {
        console.log('🚀 ~ handleSearch ~ err:', err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilter = (value: string): void => {
    setOption(value);

    switch (value) {
      case '2':
        setFilteredList([...propertyList].sort((a, b) => a.suggestion.localeCompare(b.suggestion)));
        break;

      default:
        setFilteredList(propertyList);
        break;
    }
  };
  const handleTargetListView = (targetView: string): void => {
    setView(targetView);
  };

  const handleAddToWatchList = (targetObj: IProperty): void => {
    if (user) {
      request<IPostWatchlistResponse>('post', '/watchlist/', { ...targetObj, userId: user._id })
        .then((res) => {
          console.log(res);
          getAllWatchList(updateStore);
          handleCounter();
          setOpenToast(true);
          setMessage(res.data.message);
          // setWatchlist(res.data.property);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const navigate = useNavigate();
  const handleGoBackBtn = (): void => {
    navigate('/home');
  };

  const handleRemoveWatchlist = (targetWatchlistId: string): void => {
    request('delete', `/watchlist/${targetWatchlistId}`)
      .then((res) => {
        console.log(res);
        setOpenToast(true);
        setMessage('Removed From Watchlist');
        getAllWatchList(updateStore);
        handleCounter();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchedSuburb) {
      handleSearch(searchedSuburb);
      // getNotesAndWatchlist();
    }
    handleCounter();
  }, [searchedSuburb]);

  return (
    <>
      <Header menu logo />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection={'column'}
        minHeight="100vh"
        height="100vh"
        paddingBottom={'6%'}
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Sortingbar
          text={`SEARCH RESULTS FOR "${searchedSuburb ?? ''}"`}
          handleTargetListView={handleTargetListView}
          view={view}
          totalItems={propertyList?.length ?? 0}
          option={option}
          handleFilter={handleFilter}
        />
        <motion.div
          initial={'hidden'}
          animate={'visible'}
          exit={'hidden'}
          variants={variants}
          style={{
            marginTop: '2%',
            display: 'flex',
            flexWrap: 'wrap',
            width: '82vw',
            maxHeight: '68vh',
            overflowY: 'scroll',
          }}
        >
          {loading ? (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
              <React.Fragment>
                <svg width={0} height={0}>
                  <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" stopColor="#808080" />
                      <stop offset="100%" stopColor="#C0C0C0" />
                    </linearGradient>
                  </defs>
                </svg>
                <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
              </React.Fragment>
            </Box>
          ) : filteredList?.length ? (
            filteredList?.map((item) => (
              <PropertyDetailCard
                key={item.propertyId}
                item={item}
                view={view}
                handleAddToWatchList={handleAddToWatchList}
                watchlist={watchlist}
                notes={notes}
                handleRemoveWatchlist={handleRemoveWatchlist}
              />
            ))
          ) : (
            <Box
              width={'100%'}
              height={'50vh'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box
                textAlign={'center'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'20px'}
                flexDirection={'column'}
                width={'25vw'}
              >
                <NoWatchlist />
                <Typography color={'white'} variant="h5" fontWeight={'bold'} fontFamily={'inter'}>
                  No Results Found
                </Typography>
                <Typography color={'white'} fontFamily={'inter'}>
                  Explore Related Categories or Refine Your <br />
                  Search to Find What You&apos;re Looking For
                </Typography>
                <Button
                  onClick={handleGoBackBtn}
                  sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    textTransform: 'capitalize',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    '&:hover': {
                      color: 'black',
                      backgroundColor: 'white',
                    },
                  }}
                >
                  Go To Home Page
                </Button>
              </Box>
            </Box>
          )}
        </motion.div>
      </Box>
      <Drawer />
      <RequestSubmitted
        message={message}
        open={openToast}
        handleClose={() => {
          setOpenToast(false);
        }}
      />
    </>
  );
};

export default Home;
