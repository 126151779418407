/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import success from 'assets/success.gif';
import request from 'api/request';

const PaymentSuccessful: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { userId, membership_type, membership_plan, sessionID } = params;

  const handlePaymentSuccess = (): void => {
    if (sessionID && membership_type && membership_plan && userId) {
      request('post', 'payment/save', {
        sessionID,
        membership_type,
        membership_plan,
        userId,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginBtn = (): void => {
    navigate('/login');
  };
  useEffect(() => {
    handlePaymentSuccess();
  }, [sessionID, membership_type, membership_plan, userId]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Box
        width="30%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="20px"
      >
        <img src={success} alt="" />
        <Typography fontSize="22px" fontWeight={600}>
          Card Details Added Successfully!
        </Typography>
        <Button
          onClick={handleLoginBtn}
          fullWidth
          sx={{
            '&:hover': {
              backgroundColor: 'black',
            },
            color: 'white',
            padding: '12px 0px',
            backgroundColor: 'black',
            borderRadius: '50px',
          }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSuccessful;
