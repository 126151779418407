import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, InputAdornment, Rating, TextField, Typography } from '@mui/material';
import bg from '../assets/bg_shade.svg';
// import { ReactComponent as Logo } from '../assets/smallLogo.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as BGLaptop } from '../assets/LaptopMobile.svg';
import { ReactComponent as AppStore } from '../assets/appstore.svg';
import { ReactComponent as PlayStore } from '../assets/playstore.svg';
import BgGrad from '../assets/bg_grad.svg';
import { ReactComponent as Plus } from '../assets/plus.svg';
import { ReactComponent as Minus } from '../assets/minus.svg';
import { ReactComponent as Tiktok } from '../assets/tiktok.svg';
import { ReactComponent as Insta } from '../assets/insta.svg';
// import { ReactComponent as LogoFooter } from '../assets/logoFooter.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const arr = [
  {
    name: 'Jhon Doe',
    star: 4,
    desc: 'Perfect pre-workout bar!',
    desc2:
      'The product is great to the taste. Dense and sweet. I look forward to trying other flavors.',
  },
  {
    name: 'Jhon Doe',
    star: 4,
    desc: 'Perfect pre-workout bar!',
    desc2:
      'The product is great to the taste. Dense and sweet. I look forward to trying other flavors.',
  },
  {
    name: 'Jhon Doe',
    star: 4,
    desc: 'Perfect pre-workout bar!',
    desc2:
      'The product is great to the taste. Dense and sweet. I look forward to trying other flavors.',
  },
];

const LandingPageNew: FC = () => {
  const nav = useNavigate();
  const [expand, setExpand] = useState(false);

  const handleChange = (): void => {
    setExpand(!expand);
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
        position={'relative'}
        zIndex={99}
        sx={{
          backgroundImage: `url(${BgGrad})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',

          background: `
              linear-gradient(360deg, rgba(1, 1, 1, 1) 90%, #000000 90%),
              linear-gradient(360deg, color(display-p3 0.004 0.004 0.004 / 1) 90%, color(display-p3 0.000 0.000 0.000) 90%)
            `,
        }}
      >
        <img
          style={{
            position: 'absolute',
            objectFit: 'cover',
            height: '100%',
            width: '100%',
            opacity: '0.5',
          }}
          src={bg}
          alt=""
        />
        <Box
          padding="0px 120px"
          sx={{
            '@media (max-width: 500px)': {
              padding: '0px 20px',
            },
          }}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          top="30px"
          width="100%"
          className="animate__animated animate__bounceInDown"
        >
          <Box
            sx={{
              '@media (max-width: 500px)': {
                width: '45%',
              },
            }}
          >
            <Logo
              width={'100%'}
              onClick={() => {
                nav('/login');
              }}
              style={{
                cursor: 'pointer',
              }}
            />
          </Box>

          <Button
            onClick={() => {
              nav('/login');
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
              '@media (max-width: 500px)': {
                fontSize: '12px',
                padding: '10px 18px',
              },

              // width: '40%',
            }}
          >
            Login/Signup
          </Button>
        </Box>
        <Box
          width="50%"
          sx={{
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
          gap="10px"
          display="flex"
          flexDirection="column"
        >
          <Typography
            textAlign={'center'}
            fontWeight={600}
            fontSize={'48px'}
            color={'white'}
            fontFamily={'Poppins'}
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '32px',
              },
            }}
          >
            ACCESS TO ALL LISTINGS ON <br />
            THE MARKET, EXPIRED OR
            <br /> WITHDRAWN
          </Typography>
          <Typography
            textAlign={'center'}
            fontWeight={300}
            fontSize={'18px'}
            color={'white'}
            fontFamily={'Poppins'}
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '14px',
              },
            }}
          >
            Stop cold-calls and door knocking and start speaking to people that
            <br /> actually want to sell their homes
          </Typography>

          <TextField
            fullWidth
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 1)',
              marginTop: '32px',
              borderRadius: '12px',
            }}
            inputProps={{
              style: {
                padding: '20px 0px 20px 20px',
              },
            }}
            InputProps={{
              style: {
                borderRadius: '12px',
                color: 'white',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    sx={{
                      '&:hover': {
                        backgroundColor: '#B11111',
                      },
                      borderRadius: '12px',
                      backgroundColor: '#B11111',
                      textTransform: 'unset',
                      color: 'white',
                      fontWeight: 700,
                      padding: '12px 24px',
                      '@media (max-width: 500px)': {
                        fontSize: '12px',
                        padding: '12px 10px',
                      },
                    }}
                  >
                    Get More Listings !
                  </Button>
                </InputAdornment>
              ),
            }}
            placeholder="Email Address"
          />
        </Box>
      </Box>
      {/* <Box
        sx={{
          // background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
          background: 'linear-gradient(181.24deg, #B8B8B8 -99.87%, #000000 102.74%)',
          '@media (max-width: 500px)': {
            padding: '80px 20px 100px 20px',
          },
        }}
        padding="80px 120px 100px 80px"
      >
        <Typography
          width={'100%'}
          fontWeight={600}
          fontSize={'25px'}
          color="white"
          textAlign={'center'}
          sx={{
            '@media (max-width: 500px)': {
              fontSize: '16px',
            },
          }}
        >
          SEARCH FOR NEW LISTINGS, MANAGE YOUR PIPELINE ON THE GO, RECEIVE NOTIFICATIONS.
        </Typography>
      </Box> */}
      <Box
        bgcolor={'black'}
        height={'70vh'}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={'0px 120px 0px 120px'}
        sx={{
          '@media (max-width: 500px)': {
            flexDirection: 'column',
            padding: '20px',
            height: 'unset',
          },
        }}
      >
        <Box display="flex" flexDirection={'column'} gap={'16px'} justifyContent={'flex-start'}>
          <Box display="flex" flexDirection={'column'} gap={'10px'} paddingLeft={'15%'}>
            <Typography
              width={'100%'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '28px',
                },
              }}
              fontWeight={600}
              fontSize={'40px'}
              color="white"
              lineHeight={'46.74px'}
            >
              Compatible on both Desktop and Mobile devices
            </Typography>
            <Typography fontWeight={300} fontSize={'16px'} color="white">
              Search for new listings, receive notifications or
              <br /> manage your pipeline on the go
            </Typography>
          </Box>
          <Box display="flex" marginTop={'22px'} gap={'10px'} paddingLeft={'15%'}>
            <AppStore />
            <PlayStore />
          </Box>
        </Box>
        <Box width={'90%'}>
          <BGLaptop width={'100%'} height={'100%'} />
        </Box>
      </Box>
      <Box
        bgcolor={'black'}
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        justifyContent="center"
        padding={'100px 120px 100px 120px'}
        sx={{
          '@media (max-width: 500px)': {
            padding: '80px 20px 100px 20px',
          },
        }}
        gap={'50px'}
      >
        <Typography
          sx={{
            '@media (max-width: 500px)': {
              fontSize: '28px',
            },
          }}
          fontWeight={600}
          fontSize={'38px'}
          color="white"
        >
          Frequently Asked Questions
        </Typography>
        <Box display="flex" flexDirection={'column'} gap={'32px'}>
          <Accordion
            expanded={expand}
            onChange={handleChange}
            sx={{
              backgroundColor: 'rgba(252, 252, 252, 0.05)',
            }}
          >
            <AccordionSummary
              expandIcon={expand ? <Minus /> : <Plus />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="rgba(255, 255, 255, 1)">Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="rgba(145, 155, 155, 1)">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
                ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box
        bgcolor={'black'}
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        justifyContent="center"
        padding={'100px 120px 100px 120px'}
        sx={{
          '@media (max-width: 500px)': {
            padding: '80px 20px 100px 20px',
          },
        }}
        gap={'50px'}
      >
        <Typography
          sx={{
            '@media (max-width: 500px)': {
              fontSize: '28px',
            },
          }}
          fontWeight={600}
          fontSize={'38px'}
          color="white"
        >
          Our Client Testimonial
        </Typography>
        <Box
          display="flex"
          sx={{
            '@media (max-width: 500px)': {
              flexDirection: 'column',
            },
          }}
          gap="32px"
        >
          {arr.map((item, index) => {
            return (
              <Box
                key={index}
                display="flex"
                flexDirection={'column'}
                gap="32px"
                bgcolor={'rgba(255, 255, 255, 0.05)'}
                padding={'32px'}
              >
                <Box display="flex" gap={'10px'}>
                  <Rating value={item.star}></Rating>
                  <Typography color="white">1 month ago</Typography>
                </Box>
                <Typography color="white">
                  {item.desc}
                  <br />
                  <br />
                  {item.desc2}
                </Typography>
                <Box display="flex" gap="10px" alignItems={'center'} justifyContent={'flex-end'}>
                  <Typography fontWeight={600} fontSize={'18px'} color="white">
                    {item.name}
                  </Typography>
                  <Avatar />
                </Box>
              </Box>
            );
          })}
        </Box>

        <TextField
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 1)',
            width: '40%',
            marginTop: '32px',
            borderRadius: '12px',
            '@media (max-width: 500px)': {
              width: '100%',
            },
          }}
          inputProps={{
            style: {
              padding: '20px 0px 20px 20px',
            },
          }}
          InputProps={{
            style: {
              borderRadius: '12px',
              color: 'white',
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  sx={{
                    '&:hover': {
                      backgroundColor: '#B11111',
                    },
                    borderRadius: '12px',
                    backgroundColor: '#B11111',
                    textTransform: 'unset',
                    color: 'white',
                    fontWeight: 700,
                    padding: '12px 24px',
                    '@media (max-width: 500px)': {
                      fontSize: '12px',
                      padding: '12px 10px',
                    },
                  }}
                >
                  Get More Listings !
                </Button>
              </InputAdornment>
            ),
          }}
          placeholder="Email Address"
        />
      </Box>
      <Box
        bgcolor={'rgba(0, 0, 0, 1)'}
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        justifyContent="center"
        padding={'40px 74.41px 41.98px 74.39px'}
        gap={'30px'}
      >
        <Logo />
        <Box display="flex" gap={'20px'}>
          <Typography
            onClick={() => {
              nav('/terms-and-conditions');
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            fontSize={'12px'}
            fontWeight={600}
            color={'white'}
          >
            Terms & Conditions
          </Typography>
          <Typography
            onClick={() => {
              nav('/privacy-policy');
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            fontSize={'12px'}
            fontWeight={600}
            color={'white'}
          >
            Privacy Policy
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Insta />
          <Tiktok />
        </Box>
      </Box>
    </>
  );
};

export default LandingPageNew;
