import { Box, Typography } from '@mui/material';
import Header from 'components/common/Header';
import React, { FC } from 'react';

const PrivacyPolicy: FC = () => {
  return (
    <>
      <Header back logo />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        // height="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={'column'}
          mt={'140px'}
          padding={'10px 10%'}
          sx={{
            '@media (max-width: 500px)': {
              marginTop: '70px',
            },
          }}
        >
          <Typography
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '22px',
              },
            }}
            color={'white'}
            fontSize={'32px'}
            fontWeight={600}
            mb={'15px'}
          >
            Privacy Policy
          </Typography>
          <Typography
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '14px',
              },
            }}
            color={'white'}
            mb={'15px'}
          >
            WITHDRAWN. The Application is operated by WITHDRAWN APP - FZCO (License Number 47391)
          </Typography>

          <Typography
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '18px',
              },
            }}
            fontSize={'22px'}
            color="white"
          >
            <strong>We respect your privacy</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              (a) WITHDRAWN APP - FZCO respects your right to privacy and is committed to
              safeguarding the privacy of our customers and software application users. We adhere to
              the International Privacy Law. This policy sets out how we collect and treat your
              personal information.
              <br />
              (b) Personal information means information we hold which is identifiable as being
              about you.
            </Typography>
            <strong>Collection of personal information</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              (a) WITHDRAWN APP - FZCO will, from time to time, receive and store personal
              information you enter onto our software application, provide to us directly or give to
              us in other forms.
              <br />
              (b) You may provide basic information such as your name, phone number, address and
              email address to enable us to send information, provide updates and process your
              product or service order. We may collect additional information at other times,
              including but not limited to, when you provide feedback, when you provide information
              about your personal or business affairs, change your content or email preference,
              respond to surveys and/or promotions, provide financial or credit card information, or
              communicate with our customer support.
              <br />
              (c) Additionally, we may also collect any other information you provide while
              interacting with us.
            </Typography>
            <strong>How we collect your personal information</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              WITHDRAWN APP - FZCO collects personal information from you in a variety of ways,
              including when you interact with us electronically or in person, when you access our
              software application and when we provide our services to you. We may receive personal
              information from third parties. If we do, we will protect it as set out in this
              Privacy Policy.
            </Typography>
            <strong>Use of your personal information</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              (a) WITHDRAWN APP - FZCO may use personal information collected from you to provide
              you with information, updates and our services. We may also make you aware of new and
              additional products, services and opportunities available to you. We may use your
              personal information to improve our products and services and better understand your
              needs.
              <br />
              (b) The Application may make third party social media features available to its users.
              We cannot ensure the security of any information you choose to make public in a social
              media feature. Also, we cannot ensure that parties who have access to such publicly
              available information will respect your privacy.
              <br />
              (c) WITHDRAWN APP - FZCO may contact you by a variety of measures including, but not
              limited to telephone, email, sms or mail.
            </Typography>
            <strong>Disclosure of your personal information</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              (a) We may disclose your personal information to any of our employees, officers,
              insurers, professional advisers, agents, suppliers or subcontractors insofar as
              reasonably necessary for the purposes set out in this Policy. Personal information is
              only supplied to a third party when it is required for the delivery of our services.
              <br />
              (b) We may from time to time need to disclose personal information to comply with a
              legal requirement, such as a law, regulation, court order, subpoena, warrant, in the
              course of a legal proceeding or in response to a law enforcement agency request.
              <br />
              (c) We may also use your personal information to protect the copyright, trademarks,
              legal rights, property or safety of WITHDRAWN APP - FZCO, its application, website and
              customers or third parties.
              <br />
              (d) Information that we collect may from time to time be stored, processed in or
              transferred between parties located in countries globally. These may include, but are
              not limited to United Arab Emirates.
              <br />
              (e) If there is a change of control in our business or a sale or transfer of business
              assets, we reserve the right to transfer to the extent permissible at law our user
              databases, together with any personal information and non-personal information
              contained in those databases. This information may be disclosed to a potential
              purchaser under an agreement to maintain confidentiality. We would seek to only
              disclose information in good faith and where required by any of the above
              circumstances.
              <br />
              (f) By providing us with personal information, you consent to the terms of this
              Privacy Policy and the types of disclosure covered by this Policy. Where we disclose
              your personal information to third parties, we will request that the third party
              follow this Policy regarding handling your personal information.
            </Typography>
            <strong>Security of your personal information</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              (a) WITHDRAWN APP - FZCO is committed to ensuring that the information you provide to
              us is secure. In order to prevent unauthorized access or disclosure, we have put in
              place suitable physical, electronic and managerial procedures to safeguard and secure
              information and protect it from misuse, interference, loss and unauthorized access,
              modification and disclosure.
              <br />
              (b) The transmission and exchange of information is carried out at your own risk. We
              cannot guarantee the security of any information that you transmit to us, or receive
              from us. Although we take measures to safeguard against unauthorized disclosures of
              information, we cannot assure you that personal information that we collect will not
              be disclosed in a manner that is inconsistent with this Privacy Policy.
            </Typography>
            <strong>Access to your personal information</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              (a) You may request details of personal information that we hold about you in
              accordance with the provisions of International Privacy Law. A small administrative
              fee may be payable for the provision of information. If you would like a copy of the
              information which we hold about you or believe that any information we hold on you is
              inaccurate, out of date, incomplete, irrelevant or misleading, please email us at
              contact@withdrawn.app.
              <br />
              (b) We reserve the right to refuse to provide you with information that we hold about
              you, in certain circumstances set out in International Privacy Law.
            </Typography>
            <strong>Complaints about privacy</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              If you have any complaints about our privacy practices, please feel free to send in
              details of your complaints to contact@withdrawn.app. We take complaints very seriously
              and will respond shortly after receiving written notice of your complaint.
            </Typography>
            <strong>Op out right</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              You can stop all collection of information by the Application easily by uninstalling
              the Application. You may use the standard uninstall processes as may be available as
              part of your mobile device or via the mobile application marketplace or network. You
              can also request to opt-out via email, at contact@withdrawn.app.
            </Typography>
            <strong> Changes to Privacy Policy</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              Please be aware that we may change this Privacy Policy in the future. We may modify
              this Policy at any time, in our sole discretion and all modifications will be
              effective immediately upon our posting of the modifications on our website or notice
              board. Please check back from time to time to review our Privacy Policy.
            </Typography>
            <strong> Software Application</strong>
            <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
              <strong>When you use our Application</strong>
              <br />
              <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
                When you come to our application we may collect certain information such as mobile
                unique device ID, the IP address of your mobile device, mobile operating system, the
                type of mobile internet browsers you use, and information about the way you use the
                Application. This information is used in an aggregated manner to analyze how people
                use our site, such that we can improve our service.
                <br />
              </Typography>
              <strong>Cookies</strong>
              <br />
              <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
                We may from time to time use cookies on our software application. Cookies are very
                small files which a website uses to identify you when you come back to the
                application and to store details about your use of the application. Cookies are not
                malicious programs that access or damage your computer, tablet or smartphone. Most
                devices automatically accept cookies but you can choose to reject cookies by
                changing your device settings. However, this may prevent you from taking full
                advantage of our application.
                <br />
              </Typography>
              <strong>Automatic collection</strong>
              <br />
              <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
                The software Application may collect certain information automatically, including,
                but not limited to, the type of mobile device you use, your mobile device&apos;s
                unique device ID, the IP address of your mobile device, your mobile operating
                system, the type of mobile Internet browsers you use, and information about the way
                you use the Application.
                <br />
              </Typography>

              <strong>Third parties</strong>
              <br />
              <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
                Our software application may from time to time have links to other applications or
                websites not owned or controlled by us. These links are meant for your convenience
                only. Links to third party applications and websites do not constitute sponsorship
                or endorsement or approval of these third parties. Please be aware that WITHDRAWN
                APP - FZCO is not responsible for the privacy practices of other such applications
                or websites. We encourage our users to be aware, when they leave our application or
                website, to read the privacy statements of each and every application or website
                that collects personal identifiable information.
                <br />
              </Typography>
              <strong>Geo-location</strong>
              <br />
              <Typography fontSize="14px" mt={'5px'} component="p" paragraph>
                When you visit the mobile application, we may use GPS technology (or other similar
                technology) to determine your current location in order to determine the city you
                are located within and display a location map with relevant advertisements. We will
                not share your current location with other users or partners.
              </Typography>
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
