import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Back } from '../../assets/back.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
// import { ReactComponent as Info } from '../../assets/info.svg';
import { ReactComponent as Menu } from '../../assets/menu.svg';
import { motion } from 'framer-motion';
import { variants } from 'unAuth/Login';
import MenuComponent from '../common/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

interface IHeader {
  menu?: boolean;
  back?: boolean;
  logo?: boolean;
  handleClosePlan?: () => void;
  openPlan?: boolean;
}

const Header: FC<IHeader> = ({ menu, back, logo, openPlan, handleClosePlan }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const handleClose = (): void => {
    setOpenMenu(false);
  };

  const handleLogout = (): void => {
    localStorage.clear();
    window.location.pathname = '/login';
  };
  return (
    <motion.div
      initial={'hidden'}
      animate={'visible'}
      exit={'hidden'}
      variants={variants}
      className="header_container"
    >
      <Box
        visibility={back ? 'unset' : 'hidden'}
        onClick={() => {
          if (openPlan) {
            handleClosePlan?.();
          } else {
            window.history.back();
          }
        }}
        borderRadius="100%"
        padding="22px"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          cursor: 'pointer',
          '@media (max-width: 500px)': {
            backgroundColor: 'unset',
          },
        }}
      >
        <Back />
      </Box>

      <Box
        visibility={logo ? 'unset' : 'hidden'}
        position="absolute"
        left="50%"
        top="50%"
        sx={{
          transform: 'translate(-50%, -50%)',
          '@media (max-width: 500px)': {
            visibility: window.location.pathname.includes('search') ? 'hidden' : 'unset',
          },
        }}
      >
        <Box
          sx={{
            '@media (max-width: 500px)': {
              width: '146px',
            },
          }}
        >
          <Logo width="100%" />
        </Box>
      </Box>

      <Box
        visibility={menu ? 'unset' : 'hidden'}
        display="flex"
        gap="22px"
        sx={{
          '@media (max-width: 500px)': {
            gap: 'unset',
          },
        }}
      >
        <Box
          onClick={handleLogout}
          borderRadius="100%"
          padding="22px"
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            cursor: 'pointer',
            '@media (max-width: 500px)': {
              backgroundColor: 'unset',
              padding: '8px',
            },
          }}
        >
          <LogoutIcon sx={{ color: 'white' }} />
        </Box>
        <Box
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
          borderRadius="100%"
          padding="22px"
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            cursor: 'pointer',
            '@media (max-width: 500px)': {
              backgroundColor: 'unset',
              padding: '8px',
            },
          }}
        >
          <Menu />
        </Box>
      </Box>
      {openMenu && <MenuComponent handleClose={handleClose} />}
    </motion.div>
  );
};

export default Header;
