import React, { FC } from 'react';
import { Box } from '@mui/material';
import Header from 'components/common/Header';
import { ReactComponent as Logo } from '../assets/bigLogo.svg';
import { variants } from 'unAuth/Login';
import Drawer from 'components/common/Drawer';
import { motion, AnimatePresence } from 'framer-motion';
import AutocompleteSearch from 'components/common/AutocompleteSearch';
// import Radio from '@mui/material/Radio';
// import FormControlLabel from '@mui/material/FormControlLabel';

const Search: FC = () => {
  return (
    <>
      <Header menu />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        height="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <AnimatePresence>
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={variants}
            className="search_div"
          >
            <Box
              sx={{
                '@media (max-width: 500px)': {
                  display: 'none',
                },
              }}
            >
              <motion.div animate={{ y: 0, transition: { ease: 'linear' } }} initial={{ y: -150 }}>
                <Logo />
              </motion.div>
            </Box>
            <Box
              display="none"
              sx={{
                '@media (max-width: 500px)': {
                  width: '220px',
                  display: 'block',
                },
              }}
            >
              <Logo width="100%" />
            </Box>

            <AutocompleteSearch />
          </motion.div>
        </AnimatePresence>
      </Box>
      <Drawer />
    </>
  );
};

export default Search;
