import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

interface IMenu {
  handleClose: () => void;
}

const Menu: FC<IMenu> = ({ handleClose }) => {
  const nav = useNavigate();
  return (
    <Box
      position={'absolute'}
      height={'60vh'}
      //   bgcolor={'black'}
      top={0}
      left={0}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      gap={'20px'}
      justifyContent={'center'}
      alignItems={'center'}
      zIndex={999}
      sx={{
        background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
      }}
    >
      <Typography
        onClick={() => {
          nav('/home');
        }}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: 'rgb(240, 240, 240)',
          },
        }}
        fontSize={'30px'}
        fontWeight={'900'}
        color={'rgb(240, 240, 240, 0.7)'}
        // fontFamily={'Inter'}
      >
        Home
      </Typography>
      <Typography
        onClick={() => {
          nav('/search');
        }}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: 'rgb(240, 240, 240)',
          },
        }}
        fontSize={'30px'}
        fontWeight={'900'}
        color={'rgb(240, 240, 240, 0.7)'}
        // fontFamily={'Inter'}
      >
        Search
      </Typography>
      <Typography
        onClick={() => {
          nav('/watchlist');
        }}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: 'rgb(240, 240, 240)',
          },
        }}
        fontSize={'30px'}
        fontWeight={'900'}
        color={'rgb(240, 240, 240, 0.7)'}
        // fontFamily={'Inter'}
      >
        Watchlist
      </Typography>
      <Typography
        onClick={() => {
          nav('/profile');
        }}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: 'rgb(240, 240, 240)',
          },
        }}
        fontSize={'30px'}
        fontWeight={'900'}
        color={'rgb(240, 240, 240, 0.7)'}
        // fontFamily={'Inter'}
      >
        Profile
      </Typography>
      <Box onClick={handleClose}>
        <CloseIcon
          htmlColor="white"
          sx={{
            position: 'absolute',
            right: 30,
            top: 30,
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
};

export default Menu;
