import React, { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { ReactComponent as Basic } from '../../assets/basic.svg';
import { ReactComponent as Pro } from '../../assets/free.svg';
import { ReactComponent as Free } from '../../assets/pro.svg';
import { ReactComponent as Check } from '../../assets/checkCircle.svg';
import { motion, AnimatePresence } from 'framer-motion';
import request from 'api/request';
import MobilePriceCard from 'components/MobilePriceCard';
import { toast } from 'react-toastify';
import { variants } from 'unAuth/Login';
import Header from 'components/common/Header';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '@media (max-width: 500px)': {
    width: 50,
    height: 25,
  },
  width: 70,
  height: 33,
  padding: 0,
  borderRadius: '100px',
  '& .MuiSwitch-switchBase': {
    '@media (max-width: 500px)': {
      margin: 2,
    },
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      '@media (max-width: 500px)': {
        transform: 'translateX(26px)',
      },
      transform: 'translateX(37px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    '@media (max-width: 500px)': {
      width: 20,
      height: 20,
    },
    boxSizing: 'border-box',
    width: 25,
    height: 25,
    color: 'black',
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#fff',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface IPlan {
  handleClose: () => void;
  openPlan: boolean;
}

const Plan: FC<IPlan> = ({ handleClose, openPlan }) => {
  const userJson = localStorage.getItem('user');
  const user = userJson ? JSON.parse(userJson) : undefined;
  const [yearlyPlan, setYearlyPlan] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [selected, setSelected] = React.useState({
    variant: user?.membership_plan,
    price: null,
  });

  const handlePayment = (type: string): void => {
    const body = {
      userId: user._id,
      newPlan: yearlyPlan ? 'yearly' : 'monthly',
      planType: type,
    };
    setDisableBtn(true);
    request('post', `/payment/changePlan`, body)
      .then((res) => {
        console.log(res);
        toast.success('Subscription updated');
        setDisableBtn(false);
      })
      .catch((err) => {
        console.log(err);
        setDisableBtn(false);
        toast.error(err.response.data.message);
      });
  };

  // const nav = useNavigate();
  const array = [
    {
      variant: 'free',
      logo: <Free />,
      price: 0,
      freeTraial: '7 Day',
      included: [
        'Upto 50 searches',
        'Search withdrawn listings',
        'Create Watchlist',
        'Track listings as they approach 90 days, expire or withdraw',
      ],
    },
    {
      variant: 'basic',
      logo: <Basic />,
      price: yearlyPlan ? 120 : 89,
      included: [
        'Upto 50 searches',
        'Search withdrawn listings',
        'Create Watchlist',
        'Track listings as they approach 90 days, expire or withdraw',
      ],
    },
    {
      variant: 'pro',
      logo: <Pro />,
      price: yearlyPlan ? 190 : 149,
      included: [
        'Upto 50 searches',
        'Search withdrawn listings',
        'Create Watchlist',
        'Track listings as they approach 90 days, expire or withdraw',
      ],
    },
  ];
  return (
    <>
      <Header logo menu back openPlan={openPlan} handleClosePlan={handleClose} />
      <Box
        display="flex"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
        }}
      >
        <AnimatePresence>
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={variants}
            className="plan_container"
          >
            <Box display="flex" flexDirection="column" gap="5px" textAlign="center">
              <Typography
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '24px',
                  },
                }}
                fontWeight="bold"
                fontSize="42px"
                color="white"
              >
                Change Plan
              </Typography>
            </Box>
            <Box display="flex" gap="16px" alignItems="center">
              <Typography fontWeight="regular" color="white">
                Monthly
              </Typography>
              <IOSSwitch
                checked={yearlyPlan}
                onClick={() => {
                  yearlyPlan ? setYearlyPlan(false) : setYearlyPlan(true);
                }}
              />
              <Typography fontWeight="regular" color="white">
                Annually
              </Typography>
            </Box>
            <Box
              sx={{
                '@media (max-width: 500px)': {
                  placeContent: 'flex-start',
                  overflow: 'auto',
                  width: 'inherit',
                  flexDirection: 'column',
                  padding: '15px 15px 50px 15px',
                  alignItems: 'center',
                },
              }}
              display="flex"
              gap="10px"
              justifyContent="center"
              pb={'20px'}
            >
              {array.map((item, index) => {
                return (
                  <>
                    <Box
                      key={index}
                      display="flex"
                      gap="15px"
                      flexBasis="30%"
                      flexDirection="column"
                      justifyContent={'flex-end'}
                      // border={item.variant === 'Basic' ? '1px solid #EFF0F6' : 'unset'}
                      borderRadius="24px"
                      padding="40px"
                      sx={{
                        outline: '1px solid transparent',
                        '&:hover': {
                          background: 'linear-gradient(to bottom right, #2A2929, black 45%)',
                          // border: '1px solid #EFF0F6',
                          outline: '1px solid rgba(255, 255, 255, 0.2)',

                          transform: 'scale(1.01)',
                          transition: '0.5s',
                        },
                        '@media (max-width: 500px)': {
                          flexBasis: 'unset',
                          display: 'none',
                          border: 'none',
                          minWidth: '300px',
                          padding: '20px 20px 50px 20px',
                          margin: '10px',
                        },
                      }}
                    >
                      <Box display="flex" flexDirection="column" gap="17px">
                        <Box display="flex" alignItems="center" gap="12px">
                          <Box
                            borderRadius="16px"
                            padding="22px"
                            sx={{
                              bgcolor: 'rgba(255, 255, 255, 0.1)',
                              cursor: 'pointer',
                            }}
                          >
                            {item.logo}
                          </Box>
                          <Box>
                            <Typography fontWeight="regular" color="white" fontSize="18px">
                              For Everyone
                            </Typography>
                            <Typography
                              textTransform={'capitalize'}
                              fontWeight="bold"
                              color="white"
                              fontSize="24px"
                            >
                              {item?.variant}
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Typography fontWeight="regular" color="#D9DBE9">
                          Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.{' '}
                        </Typography> */}
                      </Box>
                      <Typography fontWeight="bold" color="white" fontSize="54px">
                        {item.variant === 'free' ? item?.freeTraial : '$' + String(item?.price)}
                        <Typography
                          ml="10px"
                          component="span"
                          fontWeight="bold"
                          color="white"
                          fontSize="20px"
                        >
                          {item.variant === 'free' ? '/free trial' : '/per month'}{' '}
                        </Typography>
                      </Typography>
                      <Box display="flex" flexDirection="column" gap="24px">
                        <Typography fontWeight="bold" color="white" fontSize="18px">
                          What&apos;s Included
                        </Typography>
                        <Box position="relative" display="flex" gap="16px" flexDirection="column">
                          <Box
                            sx={{
                              background:
                                'linear-gradient(to bottom, rgba(1, 1, 1, 0) 0%, black 100%)',
                              '@media (max-width: 500px)': {
                                display: 'none',
                              },
                            }}
                            position="absolute"
                            width="100%"
                            height="64%"
                            bottom={0}
                          ></Box>
                          {item?.included.map((included, index) => {
                            return (
                              <Box key={index} display="flex" gap="14px">
                                <Box>
                                  <Check />
                                </Box>
                                <Typography
                                  sx={{
                                    '@media (max-width: 500px)': {
                                      fontSize: '14px',
                                    },
                                  }}
                                  fontWeight="regular"
                                  color="white"
                                  fontSize="18px"
                                >
                                  {included}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                      <Button
                        disabled={disableBtn}
                        onClick={() => {
                          handlePayment(item.variant);
                        }}
                        fullWidth
                        sx={{
                          '&:hover': {
                            backgroundColor: 'white',
                          },

                          '@media (max-width: 500px)': {
                            backgroundColor: 'transparent',
                            border: '1px solid white',
                            color: 'white',
                          },

                          borderRadius: '12px',
                          backgroundColor: 'white',
                          textTransform: 'unset',
                          color: 'black',
                          padding: '12px 24px',
                        }}
                      >
                        Choose Plan
                      </Button>
                    </Box>
                    <MobilePriceCard item={item} selected={selected} setSelected={setSelected} />
                  </>
                );
              })}
              <Button
                disabled={disableBtn}
                onClick={() => {
                  handlePayment(selected.variant);
                }}
                // fullWidth
                sx={{
                  display: 'none',
                  '&:hover': {
                    backgroundColor: 'white',
                  },

                  '@media (max-width: 500px)': {
                    backgroundColor: 'transparent',
                    border: '1px solid white',
                    color: 'white',
                    display: 'block',
                  },

                  borderRadius: '12px',
                  backgroundColor: 'white',
                  textTransform: 'unset',
                  color: 'black',
                  padding: '16px 24px',
                  width: '70%',
                  mt: '20px',
                }}
              >
                Choose Plan
              </Button>
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
    </>
  );
};

export default Plan;
