import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from 'components/common/Header';
import { ReactComponent as LargeInfo } from 'assets/info-circle-outlined.svg';
import { useNavigate } from 'react-router-dom';

export const style = {
  input: {
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

const ErrorScreen: FC = () => {
  const navigate = useNavigate();
  const handleGoBackBtn = (): void => {
    navigate('/login');
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
      height="100vh"
      sx={{
        background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
      }}
    >
      <Header />

      <Box
        textAlign={'center'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'20px'}
        flexDirection={'column'}
        width={'25vw'}
      >
        <LargeInfo />
        <Typography color={'white'} variant="h5" fontWeight={'bold'} fontFamily={'inter'}>
          Oops! Something Went Wrong
        </Typography>
        {/* <Typography color={'white'} fontFamily={'inter'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim
        </Typography> */}
        <Button
          onClick={handleGoBackBtn}
          sx={{
            color: 'black',
            backgroundColor: 'white',
            textTransform: 'capitalize',
            padding: '10px 20px',
            borderRadius: '8px',
            '&:hover': {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        >
          Go To Home Page
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorScreen;
