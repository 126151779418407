// let baseUrl = 'http://localhost:5000/api/v1';
let baseUrl = 'http://localhost:5000/api/v1';
let mediaUrl = 'http://localhost:5000';
const appName = 'withdrawn';
let env = 'dev';

// Update the baseUrl and mediaUrl on every environment
switch (window.location.host) {
  case 'dev.withdrawn.app':
    baseUrl = 'https://dev-api.withdrawn.app/api/v1';
    mediaUrl = 'https://dev-api.withdrawn.app';
    break;

  case 'withdrawn.app':
    baseUrl = 'https://api.withdrawn.app/api/v1';
    mediaUrl = 'https://api.withdrawn.app';
    env = 'prod';
    break;
}

export { baseUrl, mediaUrl, appName, env };
