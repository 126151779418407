import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import request from 'api/request';
import toast from 'react-hot-toast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '30%',
  '@media (max-width: 500px)': {
    width: '100%',
    height: '100%',
    border: 'none',
    gap: '32px',
    padding: '20px',
    borderRadius: '0px',
  },
  bgcolor: 'black',
  borderRadius: '20px',
  border: '1px solid white',
  boxShadow: 54,
  p: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  '&:focus-visible': {
    outline: 'none',
  },
};

interface IAddNote {
  open: boolean;
  handleClose: () => void;
  userId: string;
  propertyId: string;
  image: string;
  propertyTitle: string;
  getUserNotesByPropertyId: () => void;
  setOpenToast: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
}
const AddNote: FC<IAddNote> = ({
  open,
  handleClose,
  userId,
  propertyId,
  image,
  propertyTitle,
  setOpenToast,
  setMessage,
  getUserNotesByPropertyId,
}) => {
  const [note, setNote] = useState('');

  const addNote = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions

    if (userId && propertyTitle && image && note && propertyId) {
      request('post', `/watchlist/notes`, {
        userId,
        propertyTitle,
        image,
        message: note,
        propertyId,
        date: new Date(),
      })
        .then(() => {
          handleClose();
          getUserNotesByPropertyId();
          setOpenToast(true);
          setMessage('Note added successfully.');
          setNote('');
        })
        .catch((err) => {
          console.log('🚀 ~ addNote ~ err:', err);
          toast.error(<b>There was an error. Please try again later.</b>);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
          <Typography
            fontWeight="bold"
            fontSize="32px"
            color="white"
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '22px',
                textAlign: 'left',
              },
            }}
          >
            Add Your Note
          </Typography>
          {/* <Box display="flex" justifyContent="center">
            <Typography width="60%" fontWeight="regular" color="white">
              Lorem ipsum dolor sit amet, consectetur adipiscing
            </Typography>
          </Box> */}
        </Box>
        <Box display="flex" flexDirection="column" gap="8px" width="100%">
          <Typography fontSize="16px" color="white">
            Note
          </Typography>
          <textarea
            className="textArea"
            autoFocus
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            rows={5}
            style={{
              border: '1px solid white',
              backgroundColor: 'rgba(255,255,255,0.2)',
              borderRadius: '12px',
              padding: '16px 32px',
              color: 'white',
              outline: 'none',
            }}
            placeholder="Feel Free to Write"
          />
        </Box>
        <Box
          sx={{
            '@media (max-width: 500px)': {
              mt: 'auto',
            },
          }}
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Button
            onClick={addNote}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Add
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'black',
              },
              borderRadius: '12px',
              backgroundColor: 'black',
              textTransform: 'unset',
              color: 'white',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNote;
