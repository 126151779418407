import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import request from 'api/request';
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as SearchFilled } from 'assets/searchFilled.svg';
import { useNavigate } from 'react-router-dom';

interface ISearchOption {
  isUnit: boolean;
  stateId: number;
  streetId: number;
  countryId: number;
  localityId: number;
  postcodeId: number;
  propertyId: number;
  suggestion: string;
  councilAreaId: number;
  suggestionType: string;
  isBodyCorporate: boolean;
  isActiveProperty: boolean;
}

const style = {
  input: {
    padding: '16px 32px',
    '@media (max-width: 500px)': {
      padding: '12px 32px',
    },
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

interface IPropertySearchResponse {
  suggestions: ISearchOption[];
}

const autoCompleteStyle = {
  input: {
    padding: '9px 15px',
    '@media (max-width: 500px)': {
      padding: '12px 32px',
    },
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};
const AutocompleteSearch: FC = () => {
  const [search, setSearch] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<ISearchOption>();
  const [searchedProperties, setSearchProperties] = useState<ISearchOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const nav = useNavigate();

  const handleSearch = (targetValue: string): void => {
    setSearch(targetValue);
    if (targetValue?.length > 2) {
      setLoading(true);
      request<IPropertySearchResponse>('get', `/corelogic/property-search?q=${targetValue}`)
        .then((res) => {
          setLoading(false);
          if (res.data && Array.isArray(res.data.suggestions)) {
            setSearchProperties(res.data.suggestions);
          } else {
            setSearchProperties([]);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log('err', err);
          setSearchProperties([]);
        });
    } else {
      setSearchProperties([]);
    }
  };

  const handleSearchChange = (_event: React.SyntheticEvent<Element, Event>, value: any): void => {
    console.log('🚀 ~ handleSearchChange ~ value:', value);
    setSelectedItem(value);
  };

  const handleSearchBtn = (): void => {
    if (selectedItem?.suggestionType === 'address') {
      nav(`/details/${encodeURIComponent(selectedItem?.suggestion)}/${selectedItem.propertyId}`);
    } else if (selectedItem?.suggestionType === 'locality') {
      nav(
        `/suburb-details/${selectedItem?.suggestion}/${selectedItem?.localityId}/${selectedItem?.suggestionType}`,
      );
    } else if (selectedItem?.suggestionType === 'street') {
      nav(
        `/suburb-details/${selectedItem?.suggestion}/${selectedItem?.streetId}/${selectedItem?.suggestionType}`,
      );
    } else if (selectedItem?.suggestionType === 'postcode') {
      nav(
        `/suburb-details/${selectedItem?.suggestion}/${selectedItem?.postcodeId}/${selectedItem?.suggestionType}`,
      );
    }
  };

  useEffect(() => {
    if (search === '') {
      setSearchProperties([]);
    }
  }, [search]);
  return (
    <Box position="relative" width="100%">
      <Autocomplete
        sx={autoCompleteStyle}
        fullWidth
        freeSolo
        options={searchedProperties}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-listbox': {
                background: 'linear-gradient(to bottom, #131313 45%, black 100%)',
                '& .MuiAutocomplete-option': {
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '14px',
                },
              },
            },
          },
        }}
        getOptionLabel={(option: any) => {
          if (typeof option === 'object') {
            return option?.suggestion;
          } else {
            return option;
          }
        }}
        onChange={(event, value) => {
          handleSearchChange(event, value);
        }}
        renderInput={(params) => {
          const { InputProps, inputProps, ...rest } = params;
          return (
            <TextField
              {...rest}
              id="outlined-basic"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchBtn();
                }
              }}
              variant="outlined"
              name="password"
              autoComplete="off"
              type="text"
              placeholder="Search by Property, Street, Suburb, Postcode"
              fullWidth
              value={search}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              sx={style}
              inputProps={{
                ...inputProps,
              }}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: '6px' }}>
                    {loading ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: 'white',
                        }}
                      />
                    ) : (
                      <SearchFilled width={24} height={24} />
                    )}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                    <Button
                      onClick={() => {
                        handleSearchBtn();
                        console.log('object');
                      }}
                      disabled={search === ''}
                      sx={{
                        background: 'transparent',
                        color: 'white',
                        textTransform: 'capitalize',
                        padding: '15px 22px',
                        borderTopRightRadius: '12px',
                        borderTopLeftRadius: '0px',
                        borderBottomRightRadius: '12px',
                        borderBottomLeftRadius: '0px',
                        '&:hover': {
                          background: 'white',
                          color: 'black',
                        },
                      }}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '12px',
                  color: 'white',
                  paddingRight: '1px',
                },
              }}
            />
          );
        }}
      />
    </Box>
  );
};

export default AutocompleteSearch;
