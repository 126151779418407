import React, { FC, useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
// import Header from 'components/common/Header';
import { ReactComponent as Verified } from '../../assets/verified.svg';
import { ReactComponent as Unverified } from '../../assets/unverified.svg';
import { motion, AnimatePresence } from 'framer-motion';

interface IRequestSubmitted {
  message: string;
  handleClose: () => void;
  open: boolean;
  fail?: boolean;
}

const MotionBox = motion(Box);

const RequestSubmitted: FC<IRequestSubmitted> = ({ message, handleClose, open, fail }) => {
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (open) {
      timer = setTimeout(() => {
        handleClose();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);
  return (
    <>
      {/* <Header logo back /> */}
      <AnimatePresence>
        {open && (
          <Modal closeAfterTransition open={open}>
            <MotionBox
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              gap="30px"
              display="flex"
              flexDirection="column"
              padding="30px"
              alignItems="center"
              border="1px solid #FFFFFF"
              borderRadius="12px"
              // width="30%"
              position={'absolute'}
              sx={{
                background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
                top: '50%',
                right: '50%',
                transform: 'translate(50%, -50%)',
                '@media (max-width: 500px)': {
                  width: '60%',
                  gap: '20px',
                },
              }}
            >
              <Box
                sx={{
                  '@media (max-width: 500px)': {
                    width: '25%',
                  },
                }}
              >
                {!fail ? <Verified width={'100%'} /> : <Unverified width={'100%'} />}
              </Box>
              <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
                <Typography
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '15px',
                    },
                  }}
                  fontWeight="bold"
                  fontSize="22px"
                  color="white"
                >
                  {message}
                </Typography>
                {/* <Box display="flex" justifyContent="center">
            <Typography fontWeight="regular" color="white">
              It will be reviewed and processed within 24 hours. You will be notified immediately.
              In case of any errors, one of our team member will be in touch to proceed with your
              application. your login details are on your Email..
            </Typography>
          </Box> */}
              </Box>
              {/* <Button
                fullWidth
                onClick={handleClose}
                sx={{
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  textTransform: 'unset',
                  color: 'black',
                  padding: '12px 24px',
                }}
              >
                Done
              </Button> */}
            </MotionBox>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default RequestSubmitted;
