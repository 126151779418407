import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Modal, Typography, TextField } from '@mui/material';
import request from 'api/request';
import { IUserResponse } from 'types/user';
// import { toast } from 'react-toastify';
// import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
// import { styled } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '30%',
  '@media (max-width: 500px)': {
    width: '100%',
    height: '100%',
    border: 'none',
    gap: '32px',
    padding: '20px',
    borderRadius: '0px',
  },
  bgcolor: 'black',
  borderRadius: '20px',
  border: '1px solid white',
  boxShadow: 24,
  p: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  '&:focus-visible': {
    outline: 'none',
  },
};
const inputStyle = {
  input: {
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

interface IAddNote {
  open: boolean;
  handleClose: () => void;
  setOpenPassword: (val: boolean) => void;
  handleChange: (name: string, value: string) => void;
  setOpenToast: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
  userId?: string;
  updateUser: {
    firstName: string;
    lastName: string;
    email: string;
  };
}
const AddNote: FC<IAddNote> = ({
  open,
  handleClose,
  setOpenPassword,
  userId,
  updateUser,
  handleChange,
  setOpenToast,
  setMessage,
}) => {
  const handleUpdate = (): void => {
    const body = updateUser;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('put', `/users/${userId}`, body)
      .then((res) => {
        console.log(res);
        localStorage.setItem('user', JSON.stringify((res.data as IUserResponse)?.user));
        window.dispatchEvent(new Event('storage'));
        handleClose();
        setMessage('Edited Successfully');
        setOpenToast(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenPassword = (): void => {
    setOpenPassword(true);
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" flexDirection="column" gap="12px" textAlign="center">
          <Typography
            fontWeight="bold"
            fontSize="32px"
            color="white"
            sx={{
              '@media (max-width: 500px)': {
                fontSize: '22px',
                textAlign: 'left',
              },
            }}
          >
            Edit Profile
          </Typography>
          {/* <Box display="flex" justifyContent="center">
            <Typography width="60%" fontWeight="regular" color="white">
              Lorem ipsum dolor sit amet doloroli
            </Typography>
          </Box> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          width="100%"
          sx={{
            '@media (max-width: 500px)': {
              gap: '20px',
            },
          }}
        >
          <Box
            display="flex"
            gap="20px"
            justifyContent="space-between"
            sx={{
              '@media (max-width: 500px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Typography fontSize="16px" color="white">
                First Name
              </Typography>
              <TextField
                sx={inputStyle}
                inputProps={{
                  style: {
                    padding: '16px 32px',
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    color: 'white',
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                placeholder="First Name"
                fullWidth
                name="firstName"
                value={updateUser.firstName}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="8px" width="100%">
              <Typography fontSize="16px" color="white">
                Last Name
              </Typography>
              <TextField
                sx={inputStyle}
                inputProps={{
                  style: {
                    padding: '16px 32px',
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    color: 'white',
                  },
                }}
                id="outlined-basic"
                variant="outlined"
                placeholder="Last Name"
                fullWidth
                name="lastName"
                value={updateUser.lastName}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography fontSize="16px" color="white">
              Email
            </Typography>
            <TextField
              sx={inputStyle}
              inputProps={{
                style: {
                  padding: '16px 32px',
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  color: 'white',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter your email address"
              fullWidth
              type="email"
              name="email"
              value={updateUser.email}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            '@media (max-width: 500px)': {
              mt: 'auto',
            },
          }}
          width="100%"
          display="flex"
          flexDirection="column"
          gap={'10px'}
        >
          <Button
            onClick={handleUpdate}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Save Changes
          </Button>
          <Button
            onClick={handleOpenPassword}
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'black',
              },
              borderRadius: '12px',
              backgroundColor: 'black',
              textTransform: 'unset',
              color: 'white',
              padding: '12px 24px',
              fontWeight: 'medium',
            }}
          >
            Change Password
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNote;
