import React, { FC } from 'react';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Google } from '../assets/google.svg';
import { ReactComponent as Apple } from '../assets/apple.svg';
import { ReactComponent as Facebook } from '../assets/facebook.svg';
import { ReactComponent as Eye } from '../assets/eye.svg';
import { useNavigate } from 'react-router-dom';

export const style = {
  input: {
    padding: '16px 32px',
    '@media (max-width: 500px)': {
      padding: '12px 32px',
    },
    '&::placeholder': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid white',
      outline: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      border: '1px solid white',
    },
  },
};

const Login: FC = () => {
  const nav = useNavigate();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        background: 'linear-gradient(to bottom right, #2A2929, black 64%)',
      }}
    >
      <Box
        gap="50px"
        display="flex"
        flexDirection="column"
        padding="50px"
        alignItems="center"
        border="1px solid #FFFFFF"
        borderRadius="12px"
        width="30%"
      >
        <Logo />
        <Box display="flex" flexDirection="column" gap="20px" width="100%">
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography fontSize="16px" color="white">
              Email
            </Typography>
            <TextField
              sx={style}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  color: 'white',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter your email address"
              fullWidth
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography color="white">Password</Typography>
            <TextField
              type="password"
              autoComplete="new-password"
              sx={style}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Eye />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '12px',
                  color: 'white',
                  paddingRight: '32px',
                },
              }}
              id="outlined-basic"
              variant="outlined"
              placeholder="*******"
              fullWidth
            />
            <Box display="flex" justifyContent="flex-end">
              <Typography
                onClick={() => {
                  nav('/forgetPassword');
                }}
                color="white"
                sx={{
                  cursor: 'pointer',
                }}
              >
                Forgot Password ?
              </Typography>
            </Box>
          </Box>
          <Button
            fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              borderRadius: '12px',
              backgroundColor: 'white',
              textTransform: 'unset',
              color: 'black',
              padding: '12px 24px',
            }}
          >
            Login
          </Button>
          <Box display="flex" gap="10px" alignItems="center" justifyContent="center">
            <Box borderBottom="1px solid white" width="30%" />
            <Typography fontSize="12px" color="white">
              OR
            </Typography>
            <Box borderBottom="1px solid white" width="30%" />
          </Box>
          <Box display="flex" flexDirection="column" gap="18px">
            <Button
              sx={{
                borderRadius: '12px',
                border: '1px solid white',
                textTransform: 'unset',
                color: 'white',
                padding: '16px',
                background: 'transparent',
              }}
              startIcon={<Google />}
            >
              Continue With Google
            </Button>
            <Button
              sx={{
                borderRadius: '12px',
                border: '1px solid white',
                textTransform: 'unset',
                color: 'white',
                padding: '16px',
                background: 'transparent',
              }}
              startIcon={<Apple />}
            >
              Continue With Apple
            </Button>
            <Button
              sx={{
                borderRadius: '12px',
                border: '1px solid white',
                textTransform: 'unset',
                color: 'white',
                padding: '16px',
                background: 'transparent',
              }}
              startIcon={<Facebook />}
            >
              Continue With Facebook
            </Button>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                nav('/register');
              }}
              margin="12px 0"
              color="white"
            >
              You don’t have an account? Signup
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
